.pub {
  &-wrapper {
    margin-bottom: vr(1);
    border: 1px solid #d9d9d9;
    border-width: 1px 0;
    padding: vr(1) 0;
    > *:last-child {
      margin-bottom: 0;
    }
  }
  &__heading {
    margin-bottom: vr(.75);
    letter-spacing: 0;
    text-align: left;
    &:before {
      content: '';
      display: block;
      @extend .ico-publicacio-destacada;
      margin-bottom: 10px;
    }
  }
  &__auth {
    @include typi(s2);
    @include italic;
    color: #9b9b9b;
    margin-bottom: vr(.125);
  }
  &__title {
    @include typi(t2);
    @include bold;
    margin-bottom: vr(.125);
  }
  &__info-1 {
    @include typi(s1);
    margin-bottom: vr(.125);
  }
  &__info-2 {
    @include typi(s1);
  }
}
