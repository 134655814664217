.main-header {
	position: relative;
	padding-top: vr(1);
	&__tools {
		float: right;
		clear: right;
	}
	&__item {
		display: none;
		text-decoration: none;
		@media #{$medium-up} {
			display: inline-block;
			vertical-align: middle;
			&:after {
				content: '';
				display: inline-block;
				/*width: 1px;
				height: 27px;
				background-color: #cfcfcf;*/
				vertical-align: middle;
				margin-left: 8px;
				margin-right: 8px;
			}
		}
		img {
			@media #{$medium-up} {
				transition: opacity .1s;
				&:hover {
				  opacity: .8;
				}
			}
		}
	}
	&__contact {
		@include typi(s2);
		color: $primary-color;
        padding-top: 4px;
		&:before {
		  content: '';
		  @extend .ico-email;
		  display: inline-block;
		  position: relative;
		  margin-right: 8px;
		  top: 1px;
		  transition: opacity .1s;
		}
        @media #{$medium-up} {
            &:after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 17px;
                background-color: #cfcfcf;
                vertical-align: middle;
                margin-left: 8px;
                margin-right: 8px;
            }
        }
		&:hover {
		  text-decoration: underline;
		  &:before {
		  	opacity: .8;
		  }
		}
	}
    .ico-social {
        display: inline-block;
        list-style: none;
        li {
            display: inline-block;
            margin:0;
            padding:0;
        }
        li:last-child {
            a {
                @media #{$medium-up} {
                    &:after {
                        margin: 0;
                    }
                }
            }
        }
    }
}
.site-title {
	font-size: 1em;
	margin: 0 0 vr(1);
	padding: 0;
	border: 0;
	position: static;
	float: left;
	@media #{$medium-up} {
		display: inline-block;
	}
	img {
		//width: 80px; //logo normal
		width: 130px; //logo 20anys
		@media #{$medium-up} {
			//width: 150px; //logo normal
			width: 200px; //logo 20anys
		}
	}
}
.logo-fixed{
    img{
        width: 125px;
    }
}
.lupa-s {
	display: none;
	@media #{$medium-up} {
		display: inline-block;
		border-right: 0;
		background-color: transparent;
		padding-left: 0;
		transition: opacity .1s;
		&:after {
			display: none;
		}
		&:hover {
		  opacity: .8;
		}
		img {
			position: relative;
			top: -2px;
		}
        &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 17px;
            background-color: #cfcfcf;
            vertical-align: middle;
            margin-left: 8px;
            margin-right: 8px;
        }
	}
}
