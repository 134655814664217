.top {
  display: none;
  position: fixed;
  right: vr(1);
  bottom: vr(1);
  transition: opacity .1s;
  z-index: 2;
  @media #{$small-only} {
    width: 42px;
    height: 42px;
  }
  &:hover,
  &:focus {
    opacity: .8;
  }
}
