.block-list {
  @include block-grid(1);
  @media #{$medium-up} {
    @include block-grid(2);
  }
  @media #{$large-up} {
    @include block-grid(3);
  }
  li {
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }
}

.block-list-2 {
  @include block-grid(1);
  @media #{$medium-up} {
    @include block-grid(2);
  }
  li {
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }
}