.menu-lang {

  text-transform: uppercase;
  li {
    display: inline-block;

    &.current {
      a {
        color: #000;
        @media #{$large-up} {
          color: $body-font-color;
        }
      }
    }
    a {
        text-decoration: none;
        padding: 0 vr(.4);
        float: left;
        border-right: 1px solid #000;
        &:hover {
          text-decoration: underline;
        }
        @media #{$large-up} {
            border-right: 0;
        }
    }
    &.last {
        a {
          @media #{$large-up} {
              border-right: 1px solid #cfcfcf;
              /*border-right-color: lighten($body-font-color,50%);*/
          }
        }
    }
  }


  &--desktop {
    @include typi(s2);
    margin-bottom: 0;
    &:after {
      margin-left: 0;
    }
    li {
      position: relative;
      top: 5px;
    }
    a {
      color: $primary-color;
    }
  }

  &--mobile {
    @include typi(s1);
    margin: vr(1.5) auto 0;
    @media #{$large-up} {
      display: none;
    }
    a {
      color: #fff;
    }
  }

}
