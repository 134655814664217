.dades {
	background-color: #d76398;
	text-align: center;
	padding: vr(2.5) 0 vr(1);
	margin-bottom: vr(2);
	position: relative;
	@media #{$medium-up} {
		.columns {
			border-right: solid 1px #c25788;
			&:last-child{
				border:none;
			}
		}
	}
	&:before {
		content: '';
		display: block;
		@extend .ico-research;
		position: absolute;
		top: -32px;
		left: 50%;
		margin-left: -30px;
		z-index:4;
	}
	h2 {
		@include typi(t1);
		text-transform: uppercase;
		@include light;
		color: #fff;
		letter-spacing: 0;
	}
	&__block {
		&:before {
			content: '';
			display: inline-block;
			margin-right: 12px;
			vertical-align: bottom;
			@media #{$medium-up} {
    			vertical-align: super;
    		}
		}
		&--1 {
			&:before {
				@extend .ico-groups;
				position: relative;
    			top: 12px;
    			margin-bottom: 17.5px;
    			@media #{$medium-up} {
    				top: 0;
    				margin-bottom: 0;
    			}
			}
		}
		&--2 {
			&:before {
				@extend .ico-investigators;
			}
		}
		&--3 {
			&:before {
				@extend .ico-articles;
			}
		}
		&--4 {
			&:before {
				@extend .ico-creba;
				position: relative;
				top: 32px;
    			margin-bottom: 36px;
				@media #{$medium-up} {
    				top: -25px;
    				margin-bottom: 0;
    			}
			}
		}
		p {
			@include typi(s1);

			display: block;
			color: #fff;
			text-transform: uppercase;
			padding: rem-calc(6 0 20);
			@media #{$medium-up} {
				text-align: left;
				padding: 0;
				display: inline-block;
			}
			span {
				@include typi(num-home);
				color: #6c1d41;
				@include black;
				display: block;
			}
		}
	}
}
