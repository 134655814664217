.donate-block {
  @include black;
  background-color: #b0bf14 !important;
  display: block;
  text-align: center;
  color: #fff !important;
  border: 0;
  padding: vr(1) !important;
  text-transform: uppercase;
  //margin-bottom: vr(1);
  transition: background-color .2s;
  outline: none;
  @media #{$medium-up} {
    //margin-bottom: vr(3) !important;
  }
  &:hover {
    background-color: darken(#b0bf14, 5%) !important;
  }
  &:before {
    content: '';
    display: block;
    margin: 0 auto vr(.5);
    @media #{$medium-up} {
      position: absolute;
      top: vr(1);
      left: 50%;
    }
  }
  &--1 {
    &:before {
      @extend .ico-donacions-voluntariat;
      @media #{$medium-up} {
        margin-left: -$ico-donacions-voluntariat-width/2;
        margin-top: ($ico-donacions-mevamanera-height - $ico-donacions-voluntariat-height) / 2;
      }
    }
  }
  &--2 {
    &:before {
      @extend .ico-donacions-llegat;
      @media #{$medium-up} {
        margin-left: -$ico-donacions-llegat-width/2;
        margin-top: ($ico-donacions-mevamanera-height - $ico-donacions-llegat-height) / 2;
      }
    }
  }
  &--3 {
    &:before {
      @extend .ico-donacions-mevamanera;
      @media #{$medium-up} {
        margin-left: -$ico-donacions-mevamanera-width/2;
      }
    }
  }
  @media #{$medium-up} {
    position: relative;
    padding-top: rem-calc(100) !important;
  }
}

.donacions {
  margin-top: vr(2);
  margin-bottom: vr(2);
}
.tabs {
  margin-left: 0;
  margin-right: 0;
  li {
    max-height: rem-calc(185);
    padding-bottom: 0;
    &:before {
      content: none;
    }
    &.active {
        a {
        background-color: darken(#b0bf14, 10%) !important;
        }
      }
    a {
      &:hover {
        background-color: darken(#b0bf14, 10%) !important;
      }
    }
  }
}
.tabs-content {
  .content {
    padding-top: rem-calc(30);
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
  }
}
.tabs-caixa {
  margin-bottom: rem-calc(30);
}