.more {
  border: 1px solid $primary-color;
  border-radius: 4px;
  width: rem-calc(45);
  height: rem-calc(45);
  margin: 0 auto;
  position: relative;
  display: block;
  color: darken($primary-color,10%);
  transition: border-radius .2s, background-color .2s, transform .2s;
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: $primary-color;
    position: absolute;
  }
  &:before {
    width: rem-calc(20);
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  &:after {
    width: 1px;
    height: rem-calc(20);
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }
  &:hover,
  &:focus {
    border: 1px solid $primary-color;
    border-radius: 2px;
    background-color: $primary-color;
    transform: rotate(90deg);
    &:before,
    &:after {
      background-color: #fff;
    }
  }
}