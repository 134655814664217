.bg {
  padding: vr(1.5) 0;
  margin-bottom: vr(2);
  background-color: #f7f7f7;
  >*:last-child {
    margin-bottom: 0;
  }
  &.clinica {
    background-color: lighten($clinica, 30%);
  }
  &.modelos {
    background-color: lighten($modelos, 30%);
  }
  &.estres {
    background-color: lighten($estres, 30%);
  }
  &.exp {
    background-color: lighten($exp, 30%);
  }
  &.neuro {
    background-color: lighten($neuro, 20%);
  }
  &.otras {
    background-color: lighten($otras, 20%);
  }
  &--darker {
    background-color: #b3b3b3;
  }
  &--grey{
      background-color:#f2f2f2f2;
    &:after {
      content: '';
      display: block;
      min-height: rem-calc(360);
      width: 1000%;
      position: absolute;
      top: 0;
      right: -1000%;
      background-color:#f2f2f2f2;
    }
  }
}
