.members-list {
  @media only screen and (min-width: em-calc(500)) {
    @include block-grid(2);
  }
  @media only screen and (min-width: em-calc(900)) {
    @include block-grid(3);
  }
  .small-9 {
    padding-left: vr(.5) !important;
  }
  &__img {
    border-radius: 100px;
  }
  &__name {
    @include typi(t2);
    @include regular;
    margin: vr(.125) 0;
    color: $primary_color;
    a {
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  &__descr {
    @include typi(s1);
    @include italic;
  }
}
