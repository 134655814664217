.basic {
  margin-bottom: vr(1);
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  legend {
    @include light;
    @include typi(t3);
    color: #6c1d41;
    text-align: center;
    padding-bottom: vr(1);
    width: 100%;
  }
  label, .type-label {
    display: block;
    margin-top: vr(1);
    margin-bottom: vr(.25);
    font-size: .75rem;
    a {
      color: $primary-color;
      &:hover {
        text-decoration: none;
      }
    }
  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="search"],
  select,
  textarea {
    @include typi(s1);
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    width: 100%;
    padding: vr(.25) vr(.5);
    color: #4c4c4c;
    &:focus {
      box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem $secondary-color;
    }
    &.error {
      border: 1px solid red;
    }
  }
  select {
    height: 40px;
    background-color: #fff;
  }
  input[type="file"]{
    font-size: 15px;
  }
  .prefix {
    padding: 0;
    height: rem-calc(30);
  }
  textarea {
    height: 6rem;
  }
  input[type="search"] {
    box-sizing: border-box;
  }
  button {
    margin-top: vr(1);
    .bg & {
      margin-bottom: 0;
    }
  }
}

// validació
label.error {
  @include typi(s1);
  margin-top: vr(.25);
  color: red;
  &:before {
    content: '';
    // @extend .correu-error;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}

// donacions
.donate-form {
  text-align: center;
  .control {
    margin-top: 0;
    padding-left: 0;
    color: inherit;
    width: 33.333%;
    float: left;
    text-align: center;
    @include typi(curs);
    padding-bottom: 2.25rem;
    @include light;
    margin-bottom: vr(1);
  }
  .control-indicator {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width:  1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
    background-color: #f6f6f6;
  }
  .control input:focus ~ .control-indicator {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .15rem #b0bf14;
  }
  .control input:checked ~ .control-indicator {
    background-color: #b0bf14;
  }
  button {
    clear: left;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .select {
    width: 15rem;
    select {
      background-color: #f6f6f6;
      background-image: linear-gradient(to bottom, #f6f6f6, #ffffff);
      border: solid 1px #d8d8d8;
    }
    &:after {
      color: $body-font-color;
    }
  }
}
.interior {
  text-align: left;
  .control {
    width: 25%;
  }
  #altra-aportacio {
    display: none;
  }
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered, .select2-results__options{
  li {
    padding-left: rem-calc(5);
    font-size: 15px;
    &:before{
      content: none;
    }
  }
}

.alert-box p {
	margin: .75em 0
}