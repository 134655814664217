.proj-block {
  text-align: center;
  margin-bottom: vr(1);
  background-color: gray;
  background-size: cover;
  background-position: 50% 50%;
  padding: vr(1);
  position: relative;
  &:hover {
    .proj-overlay {
      background-color: rgba(0,0,0,.8);
    }
  }
  @media #{$medium-up} {
    padding-bottom: vr(4);
  }
  &--1 {
    background-image: url('http://placehold.it/400');
  }
  &--2 {
    background-image: url('http://placehold.it/400');
  }
  &--3 {
    background-image: url('http://placehold.it/400');
  }
  &__title {
    text-transform: uppercase;
    color: #fff;
    margin-bottom: vr(.5);
    position: relative;
    z-index: 1;
    a {
       color: #fff;
       text-decoration: none;
       border: none;
       &:hover {
        color: $primary-color;
       }
    }
  }
  p {
    @include typi(s1);
    color: #fff;
    position: relative;
    z-index: 1;
  }
  &__desc {
    position: relative;
    z-index: 1;
  }
  &__status {
    position: relative;
    z-index: 1;
    b {
      text-transform: uppercase;
    }
    @media #{$medium-up} {
      position: absolute!important;
      bottom: vr(2.5);
      left: vr(1);
      right: vr(1);
    }
  }
  .button {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    @media #{$medium-up} {
      position: absolute;
      bottom: vr(1);
      left: vr(1);
      right: vr(1);
    }
  }
}
.proj-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 0;
  transition: background-color .2s;
}