@include typi-init;
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}
h1,
.t1 {
  @include typi('t1');
  @include light;
  text-align: center;
  margin: vr(2) 0 vr(1);
}
h2,
.t2 {
  @include typi('t2');
  @include bold;
  text-transform: uppercase;
  margin-top: vr(1.5);
  margin-bottom: vr(1);
  letter-spacing: 1.7px;
  &:first-child {
    margin-top: 0;
  }
  .bg & {
    text-align: center;
  }
}
h3,
.t3 {
  @include typi('t3');
}
h4,
.t4 {
  @include typi('t4');
  font-size: rem-calc(18);
}
h5 {
  font-weight: 900;
}

// títols específics
.type1 {
  text-transform: uppercase;
  color: #6c1d41;
  margin-bottom: vr(2);

}
.type5 {
  text-transform: uppercase;
  color: #6c1d41;
  margin-bottom: 14rem;
  font-weight: 500;
  font-size: rem-calc(50);
  margin-top: 14rem;
}
.type2 {
  @include typi(group);
  @include bold;
  margin-top: 0;
  &:after {
    content: '';
    display: block;
    height: vr(.25);
    width: rem-calc(150);
    margin-left: auto;
    margin-right: auto;
    margin-top: vr(1);
    @media only screen and (min-width: em-calc(500)) {
      width: rem-calc(300);
    }
    @media #{$medium-up} {
      height: vr(.5);
      width: rem-calc(360);
      margin: vr(1.5) auto 0;
    }
  }
  &.clinica,
  &.area--5 {
    &:after {
      background-color: $clinica;
    }
  }
  &.modelos,
  &.area--6 {
    &:after {
      background-color: $modelos;
    }
  }
  &.estres,
  &.area--7 {
    &:after {
      background-color: $estres;
    }
  }
  &.exp,
  &.area--8 {
    &:after {
      background-color: $exp;
    }
  }
  &.neurociencia,
  &.area--9 {
    &:after {
      background-color: $neuro;
    }
  }
  &.otras,
  &.area--10 {
    &:after {
      background-color: $otras;
    }
  }
  @media #{$medium-up} {
    margin-bottom: vr(1.5);
  }
}
.type3 {
  @include bold;
  margin: vr(1.5) 0;
  word-break: break-all;

}
.type4 {
  text-transform: none;
  letter-spacing: 0;
}
.research-title {
  @include black;
  text-align: left;
  letter-spacing: 0;
  padding-bottom: vr(1);
  border-bottom: 1px solid #d9d9d9;
  position: relative;
  &.js-open {
    border-bottom: 0;
    margin-bottom: 0;
  }
  &:before {
    content: '';
    display: block;
    width: rem-calc(68);
    height: rem-calc(68);
    margin-bottom: vr(.5);
  }
  &:after {
    content: '';
    display: block;
    @extend .ico-microscopi-research;
    position: absolute;
    left: 18.5px;
    top: 15.5px;
  }
  &--clinica,
  &--5 {
    &:before {
      background-color: $clinica;
    }
  }
  &--modelos,
  &--6 {
    &:before {
      background-color: $modelos;
    }
  }
  &--estres,
  &--7 {
    &:before {
      background-color: $estres;
    }
  }
  &--exp,
  &--8 {
    &:before {
      background-color: $exp;
    }
  }
  &--neuro,
  &--9 {
    &:before {
      background-color: $neuro;
    }
  }
  &--otras,
  &--10 {
    &:before {
      background-color: $otras;
    }
  }
  @media #{$medium-up} {
    border-bottom: 1px solid #d9d9d9;
  }
  a {
    color: $body-font-color;
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

// títol amb icona
.icon-title {
  text-align: center;
  // margin-top: 0;
  &:before {
    content: '';
    display: block;
    margin: 0 auto vr(1);
  }
  &--platform {
    &:before {
      @extend .ico-plataform-services;
    }
  }
  &--training {
    &:before {
      @extend .ico-training;
    }
  }
  &--news {
    &:before {
      @extend .ico-news;
    }
  }
  &--events {
    &:before {
      @extend .ico-events-home;
    }
  }
  &--jobs {
    &:before {
      @extend .ico-borsa;
    }
  }
  &--donate {
    &:before {
      @extend .ico-involucrat;
      margin-bottom: vr(.5);
    }
  }
  &--puntual {
    &:before {
      @extend .ico-donacions-puntual;
    }
  }
  &--periodica {
    &:before {
      @extend .ico-donacions-periodica;
    }
  }
  &--error {
    &:before {
      @extend .ico-404;
    }
  }
}

p, ol, ul, dl, figure, {
  margin: 0 0 vr(.75);
}
figure.header {
  margin-bottom: vr(1.5);
}
.simple-dl {
  @include cf;
  div {
    @include cf;
    border-bottom: 1px solid #d9d9d9;
  }
  dt {
      float: left;
      clear: left;
      width: rem-calc(250);
      padding: vr(.25) 0;
      @include bold;
  }
  dd {
      float: left;
      padding: vr(.25) 0;
      margin-left: 0;
      * {
        margin-bottom: 0 !important;
      }
      // llistat simple, sense klander
      ul {
        margin-left: 0;
        li {
          padding-left: 0;
          &:before {
            display: none;
          }
        }
      }
      time {
        font-size:inherit;
      }
  }
}

ol {
  padding-left: 0;
  list-style-position: outside;
  margin-left: vr(1.5);
  // @media #{$medium-up} {
  //   margin-left: vr(1.5);
  //   list-style-position: outside;
  //   padding-left: 0;
  // }
}
main ul {
  list-style: none;
  @media #{$medium-up} {
    margin-left: vr(1.5);
  }
  li {
    padding-left: vr(.75);
    position: relative;
    margin-bottom: vr(.5);
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      display: block;
      left: 0;
      top: .5em;
      position: absolute;
      background-color: $primary-color;
      border-radius: 100px;
    }
    ul {
      @include typi(s1);
      margin-top: vr(.5);
      li {
        margin-bottom: vr(.25);
        padding-left: 0;
        list-style: circle;
        margin-left: vr(1.5);
        &:before {
          display: none;
        }
      }
    }
  }
}
::selection {
  color: #fff;
  // background-color: lighten($primary-color,30);
  background-color: $secondary-color;
}
strong, b {
  @include bold;
}
em, i {
  @include italic;
}
hr {
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  margin: vr(1) 0;
  height: 0;
}
main {
  a {
    color: $primary-color;
    text-decoration: none;
    border-bottom: rem-calc(1) solid $primary-color;
    transition: border-width .1s,;

    &:hover {
      border: 0;
    }
  }
}
.mostrant {
  @include typi(s1);
  border: solid #dcdcdc;
  border-width: 2px 0;
  padding: vr(.5) 0;
  margin-bottom: vr(2);
}
.dl-link {
  &:before {
    content: '';
    // @extend .publicacions-icon-baixar-negre;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 8px;
  }
  &:hover {
    &:before {
      // @extend .publicacions-icon-baixar-taronja;
    }
  }
}
.lead {
  @include bold;
  text-align: center;
  margin-bottom: vr(2);
  @media #{$medium-up} {
    padding: 0 vr(2);
    max-width: 900px;
    margin-left:auto;
    margin-right:auto;
  }
}

// llistat simple, sense klander
.simple-list {
  margin-left: 0;
  li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}

// llistat destacat
.dest-list {
  @include bold;
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

// llistat amb filets
.borders-list {
  margin-bottom: vr(2);
  li {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: vr(.5);
    padding: 0 vr(1.5) vr(.5);
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}

// llistat projectes
.project-list {
	h3 {
	   font-size: 1rem;
	}
	dl {
	   font-size: .85rem;
	   margin-top: vr(.25);
	}
/*   @include bold; */
/*   a { */
/*     border: 0; */
/*     &:hover { */
/*       border-bottom: 1px solid; */
/*     } */
/*   } */
}

.dl-inline {
  width: 100%;
  overflow: hidden;
  dt {
    @include bold;
    float: left;
    clear: left;
    margin-right: vr(.25);
  }
  dd {
    margin: 0;
  }
}

.platform {
  ul {
  margin-bottom: vr(2);
  margin-left: 0;
  li {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: vr(.5);
    padding: 0 vr(1.5) vr(.5);
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    &:before {
      display: none;
    }
  }
  a {
    border: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}
}
// intro text
.intro-text {
  @include bold;
  margin-bottom: vr(1.5);
}

// time
time {
  color: $primary-color;
  display: block;
  @include typi(s1);
  margin-bottom: vr(.25);
  .pub &,
  .curs &,
  .training-block &,
  .simple-dl & {
    color: inherit;
    display: inline;
  }
}
