.landingAmics {
  .main-header {
  	padding-top: 0;
  	position: relative;
  	.img-top {
  		img {
  		width: 100%;
	    height: 100%;
	    -o-object-fit: cover;
	    object-fit: cover;
	    -o-object-position: center top;
	    object-position: center top;
	   }
  	}
  	.site-title {

  		position: absolute;
  		top: 10%;
  		left: 10%;
  		h1 {
  			margin-top: 0;
  		}
  		img {
  			width: 100px;
  			@media #{$medium-up} {
  				width: 150px;
  			}
  			@media #{$large-up} {
  				width: 225px;
  			}
  		}
  	}
  }
  .pis-groc {
  	background-color: #fff1da;
  	padding: rem-calc(80 0);
  	position: relative;
  	.xapa {
  		display: block;
  		margin: 0 auto;
  		margin-top: rem-calc(20);
  		@media #{$medium-up} {
	  		position: absolute;
	  		right: 0;
	  		top: -150px;
  		}

  	}
  	.btn-donatiu {
  		color: #fff;
  		text-transform: uppercase;
  		font-size: rem-calc(18);
  		background-color: #6d1c44;
  		border-radius: 44px;
  		padding: rem-calc(25 40);
  		margin: 0 auto;
  		margin-top: rem-calc(40);
  		display: block;
  		text-align: center;
  		max-width: 319px;
  		border: 2px solid #6d1c44;
  		@media #{$medium-up} {
  			font-size: rem-calc(20);
  			padding: rem-calc(33 50);
  			margin-top: rem-calc(40);
  			margin-left: 0;
  		}
  		&:hover {
  			background-color:  #fff;
  			color:  #6d1c44;

  		}
  	}
  }
  .pis-taula {
  	padding: rem-calc(80 0);
  	text-align: center;
  	h2 {
  		@include light;
  		font-size: rem-calc(35);
  		letter-spacing: 0;
  		text-align: center;
  		line-height: rem-calc(40);
  		margin-bottom: rem-calc(40);
  		@media #{$medium-up} {
  			font-size: rem-calc(45);
  			line-height: rem-calc(50);
  		}
  		span {
  			@include bold;
  			display: block;
  		}
  	}
  }
  .pis-xarxes {
  	background-color: #6d1c44;
  	padding: rem-calc(35 0 25);
  	text-align: center;
  	margin-bottom: rem-calc(40);
  	h3 {
  		font-size: rem-calc(26);
  		@include light;
  		color: #fff;
  		letter-spacing: .6px;
  		margin-bottom: rem-calc(30);
  	}
  	ul.xarxes {
  		font-size: 0;
	    margin-bottom: 0;
	    margin-left: -.75rem;
	    margin-right: -.75rem;
	    li {
	    	font-size: 1rem;
		    display: inline-block;
		    vertical-align: middle;
		    margin: 0 .75rem 35px;
		    &:before {
		    	content: none;
		    }
		    a { 
		    	text-decoration: none;
		    	border-bottom: none;
		    	&:hover {
		    		svg {
		    			opacity: .6;
		    		}
		    	}
		     }
	    }
  	}
  }
}
