.card {
  background-color: #f7f7f7;
  text-align: center;
  margin-bottom: vr(1.5);
  padding: vr(1) vr(2);
  position: relative;
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  &__title {
    letter-spacing: 0;
    margin-bottom: vr(.5);
  }
  &__img {
    border-radius: 100px;
    margin-bottom: vr(.5);
  }
  &__name {
    margin-bottom: vr(.5);
  }
  &__contact {
    border: 1px solid #d9d9d9;
    border-width: 1px 0;
    margin-bottom: vr(.5);
    padding: vr(.5) 0;
  }
  &__tel,
  &__email {
    display: block;
  }
  &__tel,
  &__email,
  &__adr,
  &__link {
    @include typi(s1);
    p {
      @include typi(s1);
    }
  }
  &__link {
    @include typi(s1);
    display: inline-block;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      @extend .ico-web;
      transition: transform .3s ease-out;
    }
    &:hover {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  &__doc {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: vr(1);
    margin-bottom: vr(1);
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
  &:after {
    @extend .ombra-caixeta-lateral;
    content: '';
    display: block;
    position: absolute;
    bottom: -$ombra-caixeta-lateral-height;
    right: 0;
  }
}
.top-up {
  @media #{$medium-up} {
    margin-top: vr(-3);
  }
}
.doc__name {
  &:before {
    content: '';
    @extend .ico-documents;
    display: block;
    margin: 0 auto 5px;
  }
}