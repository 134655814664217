.main-nav {
  background-color: $primary-color;
  text-align: center;
  padding: vr(1) 0;
  @media #{$large-up} {
    padding: 0;
    // overflow: hidden;
  }
}
.logo-fixed {
  display: none;
  @media #{$medium-up} {
    .scroll-to-fixed-fixed & {
      display: inline-block;
      margin-right: 40px;
    }
  }
}
.menu-button {
  // position: absolute;
  // right: vr(1);
  // top: vr(1);
  // z-index: 3;
  display: block;
  margin: 0 auto;
  margin-bottom: -$hamburger-layer-spacing;
  @media #{$large-up} {
    display: none;
  }
}

@media only screen and (max-width: 62.45em) {
  button[aria-expanded="false"] + ul.menu {
    display: none;
  }
}
@media only screen and (max-width: 62.45em) {
  button[aria-expanded="false"] + .menu {
    display: none;
  }
}
.js .menu {
  @media only screen and (max-width: 62.45em) {
    &.js-open {
      position: absolute;
      opacity: 1;
      left: 0;
      // margin-top: rem-calc(22);
      // margin-bottom: vr(-1);
      // right: 0;
      // min-height: 100vh;
      width: 100%;
      background: $primary-color;
      z-index: 6;
    }
  }
}
.menu {
  @media #{$large-up} {
    text-align: center;
    background: $primary-color;
  }
  .column {
    @media #{$large-up} {
      position: static; // traiem el relative perquè el submenú es pugui posicionar de punta a punta de la finestra.
    }
  }
}
.site-menu {
  //@include bold;
  @include typi(menu);
  list-style: none;
  margin: 0;
  margin-left: -$column-gutter/2;
  margin-right: -$column-gutter/2;
  padding: vr(.5) 0;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;

  @media #{$large-up} {
    margin-left: 0;
    margin-right: 0;
    .scroll-to-fixed-fixed &:not(.site-menu--2) {
      display: inline-block;
      vertical-align: middle;
      font-size: rem-calc(11);
    }
  }

  @media #{$large-up} {
    padding: 0;
    border-bottom: 0;
  }

  &--2 {
    @media #{$large-up} {
      display: none;
    }
  }
  > li {
    @media #{$large-up} {
      display: inline-block;
      // display: block;
      // float: left;
      // overflow: hidden;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
    display: block;
    padding: vr(.75) 0;

    &:hover {
        background-color: #6c1d41 !important;
        text-decoration: none;
      }
    &.is-open {
          background-color: #6c1d41 !important;
    }
    @media #{$small-only} {
      &[aria-haspopup="true"] {
        position: relative;
        // padding-right: 3rem;
        &:after {
          content: '';
          // @include sprite($menu-plegat);
          // @include retina-sprite($menu-plegat-group);
          display: block;
          position: absolute;
          right: $column-gutter/2;
          top: 50%;
          transform: translateY(-50%);
        }
        &.is-open {

          &:after {
            // @include sprite($menu-desplegat);
            // @include retina-sprite($menu-desplegat-group);
          }
        }
      }
    }
    &[aria-current="page"] {
      background-color: darken(#6c1d41,8%);
      &:hover {
        text-decoration: none;
      }
    }
    @media #{$large-up} {
      color: #fff;
      display: inline-block;
      padding: 1.15rem vr(.5);
      border-bottom: 0;
      font-size: rem-calc(13);
      &:hover {
        background-color: #6c1d41 !important;
        text-decoration: none;
      }

    }
    @media only screen and (min-width: 73.45em) {
      padding: 1.15rem vr(.75);
      font-size: rem-calc(14.2222);
    }

  }
  .es & {
    a {

      @media #{$medium-up} {
        font-size:rem-calc(12);
      }
      @media #{$large-up} {
        padding: 1.15rem vr(.35);
      }
      @media only screen and (min-width: 73.45em) {
        padding: 1.15rem vr(.5);
        font-size: rem-calc(13.8);
      }
    }

  }
}

// item amb submenú obert
.is-hovered {
  > a {
    background-color: #6c1d41 !important;
    &:hover {
      text-decoration: none;
    }
  }
}

// submenú
[aria-label="submenu"] {
  @include regular;
  background-color: #6c1d41;
  margin: 0;
  list-style: none;
  @media #{$large-up} {
    z-index: 6;
    background-color: #6c1d41;
    display: block;

  }
  @media #{$xlarge-up} {
     padding: 0 100px;
  }
  li {
    @media #{$large-up} {
      display: inline-block;
    }

  }
  a {
    color: #ffc7e1;
    padding: vr(.5) 0;
    @media #{$large-up} {
      color: #fff;
      color:#ffc7e1;
      display: inline-block;
      padding: 1.07rem 1.5rem;
    }
    &.active {
      //background-color: #A04872;
      background-color: transparent;
      color: #fff;
    }
  }
}

.contact-links {
  font-size: 0;
  padding: vr(.75) 0;
  text-align: center;
  @media #{$large-up} {
    display: none;
  }
  a {
    @include typi(s1);
    text-decoration: none;
    color: #fff;
    display: inline-block;
    padding: .25em 1em;
    border-right: 1px solid #000;
    &:last-child {
      border-right: 0;
    }
  }
}
.contact {
  &:before {
    content: '';
    @extend .ico-correu;
    display: inline-block;
    position: relative;
    margin-right: 8px;
    top: 1px;
  }
  &:hover {
    text-decoration: underline;
  }
}

// 9. Simple dropdowns http://heydonworks.com/practical_aria_examples/#submenus
[aria-haspopup="true"] {
  position: relative;
}

[aria-haspopup="true"] span {
  font-size: 0.85em;
  padding-left: 0.25em;
}

ul[aria-hidden="true"] {
  display: none;
}

[aria-haspopup="true"] + ul[aria-hidden="false"] {
  margin-top: 0;
  display: block;
  @media #{$large-up} {
    position: absolute;
    width: 100%;
    left: 0;
  }
}

[aria-haspopup="true"] + ul li {
  float: none;
}