// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$compartir-copiar-link-name: 'compartir-copiar-link';
$compartir-copiar-link-x: 203px;
$compartir-copiar-link-y: 116px;
$compartir-copiar-link-offset-x: -203px;
$compartir-copiar-link-offset-y: -116px;
$compartir-copiar-link-width: 23px;
$compartir-copiar-link-height: 23px;
$compartir-copiar-link-total-width: 468px;
$compartir-copiar-link-total-height: 301px;
$compartir-copiar-link-image: 'img/spritesheet.png?1625128582786';
$compartir-copiar-link: (203px, 116px, -203px, -116px, 23px, 23px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-copiar-link', );
$compartir-correu-name: 'compartir-correu';
$compartir-correu-x: 342px;
$compartir-correu-y: 123px;
$compartir-correu-offset-x: -342px;
$compartir-correu-offset-y: -123px;
$compartir-correu-width: 27px;
$compartir-correu-height: 18px;
$compartir-correu-total-width: 468px;
$compartir-correu-total-height: 301px;
$compartir-correu-image: 'img/spritesheet.png?1625128582786';
$compartir-correu: (342px, 123px, -342px, -123px, 27px, 18px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-correu', );
$compartir-facebook-name: 'compartir-facebook';
$compartir-facebook-x: 253px;
$compartir-facebook-y: 202px;
$compartir-facebook-offset-x: -253px;
$compartir-facebook-offset-y: -202px;
$compartir-facebook-width: 22px;
$compartir-facebook-height: 22px;
$compartir-facebook-total-width: 468px;
$compartir-facebook-total-height: 301px;
$compartir-facebook-image: 'img/spritesheet.png?1625128582786';
$compartir-facebook: (253px, 202px, -253px, -202px, 22px, 22px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-facebook', );
$compartir-google-name: 'compartir-google';
$compartir-google-x: 203px;
$compartir-google-y: 92px;
$compartir-google-offset-x: -203px;
$compartir-google-offset-y: -92px;
$compartir-google-width: 24px;
$compartir-google-height: 24px;
$compartir-google-total-width: 468px;
$compartir-google-total-height: 301px;
$compartir-google-image: 'img/spritesheet.png?1625128582786';
$compartir-google: (203px, 92px, -203px, -92px, 24px, 24px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-google', );
$compartir-link-name: 'compartir-link';
$compartir-link-x: 319px;
$compartir-link-y: 202px;
$compartir-link-offset-x: -319px;
$compartir-link-offset-y: -202px;
$compartir-link-width: 21px;
$compartir-link-height: 21px;
$compartir-link-total-width: 468px;
$compartir-link-total-height: 301px;
$compartir-link-image: 'img/spritesheet.png?1625128582786';
$compartir-link: (319px, 202px, -319px, -202px, 21px, 21px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-link', );
$compartir-linkedin-name: 'compartir-linkedin';
$compartir-linkedin-x: 182px;
$compartir-linkedin-y: 148px;
$compartir-linkedin-offset-x: -182px;
$compartir-linkedin-offset-y: -148px;
$compartir-linkedin-width: 22px;
$compartir-linkedin-height: 23px;
$compartir-linkedin-total-width: 468px;
$compartir-linkedin-total-height: 301px;
$compartir-linkedin-image: 'img/spritesheet.png?1625128582786';
$compartir-linkedin: (182px, 148px, -182px, -148px, 22px, 23px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-linkedin', );
$compartir-twitter-name: 'compartir-twitter';
$compartir-twitter-x: 228px;
$compartir-twitter-y: 202px;
$compartir-twitter-offset-x: -228px;
$compartir-twitter-offset-y: -202px;
$compartir-twitter-width: 25px;
$compartir-twitter-height: 22px;
$compartir-twitter-total-width: 468px;
$compartir-twitter-total-height: 301px;
$compartir-twitter-image: 'img/spritesheet.png?1625128582786';
$compartir-twitter: (228px, 202px, -228px, -202px, 25px, 22px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'compartir-twitter', );
$fletxa-crowdfunding-anterior-name: 'fletxa-crowdfunding-anterior';
$fletxa-crowdfunding-anterior-x: 275px;
$fletxa-crowdfunding-anterior-y: 202px;
$fletxa-crowdfunding-anterior-offset-x: -275px;
$fletxa-crowdfunding-anterior-offset-y: -202px;
$fletxa-crowdfunding-anterior-width: 22px;
$fletxa-crowdfunding-anterior-height: 21px;
$fletxa-crowdfunding-anterior-total-width: 468px;
$fletxa-crowdfunding-anterior-total-height: 301px;
$fletxa-crowdfunding-anterior-image: 'img/spritesheet.png?1625128582786';
$fletxa-crowdfunding-anterior: (275px, 202px, -275px, -202px, 22px, 21px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'fletxa-crowdfunding-anterior', );
$fletxa-crowdfunding-seguent-name: 'fletxa-crowdfunding-seguent';
$fletxa-crowdfunding-seguent-x: 297px;
$fletxa-crowdfunding-seguent-y: 202px;
$fletxa-crowdfunding-seguent-offset-x: -297px;
$fletxa-crowdfunding-seguent-offset-y: -202px;
$fletxa-crowdfunding-seguent-width: 22px;
$fletxa-crowdfunding-seguent-height: 21px;
$fletxa-crowdfunding-seguent-total-width: 468px;
$fletxa-crowdfunding-seguent-total-height: 301px;
$fletxa-crowdfunding-seguent-image: 'img/spritesheet.png?1625128582786';
$fletxa-crowdfunding-seguent: (297px, 202px, -297px, -202px, 22px, 21px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'fletxa-crowdfunding-seguent', );
$ico-404-name: 'ico-404';
$ico-404-x: 285px;
$ico-404-y: 150px;
$ico-404-offset-x: -285px;
$ico-404-offset-y: -150px;
$ico-404-width: 52px;
$ico-404-height: 52px;
$ico-404-total-width: 468px;
$ico-404-total-height: 301px;
$ico-404-image: 'img/spritesheet.png?1625128582786';
$ico-404: (285px, 150px, -285px, -150px, 52px, 52px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-404', );
$ico-articles-name: 'ico-articles';
$ico-articles-x: 160px;
$ico-articles-y: 92px;
$ico-articles-offset-x: -160px;
$ico-articles-offset-y: -92px;
$ico-articles-width: 43px;
$ico-articles-height: 56px;
$ico-articles-total-width: 468px;
$ico-articles-total-height: 301px;
$ico-articles-image: 'img/spritesheet.png?1625128582786';
$ico-articles: (160px, 92px, -160px, -92px, 43px, 56px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-articles', );
$ico-arxiu-jpg-name: 'ico-arxiu-jpg';
$ico-arxiu-jpg-x: 86px;
$ico-arxiu-jpg-y: 264px;
$ico-arxiu-jpg-offset-x: -86px;
$ico-arxiu-jpg-offset-y: -264px;
$ico-arxiu-jpg-width: 29px;
$ico-arxiu-jpg-height: 31px;
$ico-arxiu-jpg-total-width: 468px;
$ico-arxiu-jpg-total-height: 301px;
$ico-arxiu-jpg-image: 'img/spritesheet.png?1625128582786';
$ico-arxiu-jpg: (86px, 264px, -86px, -264px, 29px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-arxiu-jpg', );
$ico-arxiu-keynote-name: 'ico-arxiu-keynote';
$ico-arxiu-keynote-x: 345px;
$ico-arxiu-keynote-y: 224px;
$ico-arxiu-keynote-offset-x: -345px;
$ico-arxiu-keynote-offset-y: -224px;
$ico-arxiu-keynote-width: 30px;
$ico-arxiu-keynote-height: 31px;
$ico-arxiu-keynote-total-width: 468px;
$ico-arxiu-keynote-total-height: 301px;
$ico-arxiu-keynote-image: 'img/spritesheet.png?1625128582786';
$ico-arxiu-keynote: (345px, 224px, -345px, -224px, 30px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-arxiu-keynote', );
$ico-arxiu-pdf-name: 'ico-arxiu-pdf';
$ico-arxiu-pdf-x: 225px;
$ico-arxiu-pdf-y: 224px;
$ico-arxiu-pdf-offset-x: -225px;
$ico-arxiu-pdf-offset-y: -224px;
$ico-arxiu-pdf-width: 30px;
$ico-arxiu-pdf-height: 31px;
$ico-arxiu-pdf-total-width: 468px;
$ico-arxiu-pdf-total-height: 301px;
$ico-arxiu-pdf-image: 'img/spritesheet.png?1625128582786';
$ico-arxiu-pdf: (225px, 224px, -225px, -224px, 30px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-arxiu-pdf', );
$ico-arxiu-png-name: 'ico-arxiu-png';
$ico-arxiu-png-x: 57px;
$ico-arxiu-png-y: 264px;
$ico-arxiu-png-offset-x: -57px;
$ico-arxiu-png-offset-y: -264px;
$ico-arxiu-png-width: 29px;
$ico-arxiu-png-height: 31px;
$ico-arxiu-png-total-width: 468px;
$ico-arxiu-png-total-height: 301px;
$ico-arxiu-png-image: 'img/spritesheet.png?1625128582786';
$ico-arxiu-png: (57px, 264px, -57px, -264px, 29px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-arxiu-png', );
$ico-arxiu-ppt-name: 'ico-arxiu-ppt';
$ico-arxiu-ppt-x: 285px;
$ico-arxiu-ppt-y: 224px;
$ico-arxiu-ppt-offset-x: -285px;
$ico-arxiu-ppt-offset-y: -224px;
$ico-arxiu-ppt-width: 30px;
$ico-arxiu-ppt-height: 31px;
$ico-arxiu-ppt-total-width: 468px;
$ico-arxiu-ppt-total-height: 301px;
$ico-arxiu-ppt-image: 'img/spritesheet.png?1625128582786';
$ico-arxiu-ppt: (285px, 224px, -285px, -224px, 30px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-arxiu-ppt', );
$ico-arxiu-txt-name: 'ico-arxiu-txt';
$ico-arxiu-txt-x: 315px;
$ico-arxiu-txt-y: 224px;
$ico-arxiu-txt-offset-x: -315px;
$ico-arxiu-txt-offset-y: -224px;
$ico-arxiu-txt-width: 30px;
$ico-arxiu-txt-height: 31px;
$ico-arxiu-txt-total-width: 468px;
$ico-arxiu-txt-total-height: 301px;
$ico-arxiu-txt-image: 'img/spritesheet.png?1625128582786';
$ico-arxiu-txt: (315px, 224px, -315px, -224px, 30px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-arxiu-txt', );
$ico-borsa-name: 'ico-borsa';
$ico-borsa-x: 143px;
$ico-borsa-y: 171px;
$ico-borsa-offset-x: -143px;
$ico-borsa-offset-y: -171px;
$ico-borsa-width: 49px;
$ico-borsa-height: 36px;
$ico-borsa-total-width: 468px;
$ico-borsa-total-height: 301px;
$ico-borsa-image: 'img/spritesheet.png?1625128582786';
$ico-borsa: (143px, 171px, -143px, -171px, 49px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-borsa', );
$ico-correu-name: 'ico-correu';
$ico-correu-x: 386px;
$ico-correu-y: 123px;
$ico-correu-offset-x: -386px;
$ico-correu-offset-y: -123px;
$ico-correu-width: 18px;
$ico-correu-height: 13px;
$ico-correu-total-width: 468px;
$ico-correu-total-height: 301px;
$ico-correu-image: 'img/spritesheet.png?1625128582786';
$ico-correu: (386px, 123px, -386px, -123px, 18px, 13px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-correu', );
$ico-creba-name: 'ico-creba';
$ico-creba-x: 329px;
$ico-creba-y: 54px;
$ico-creba-offset-x: -329px;
$ico-creba-offset-y: -54px;
$ico-creba-width: 95px;
$ico-creba-height: 20px;
$ico-creba-total-width: 468px;
$ico-creba-total-height: 301px;
$ico-creba-image: 'img/spritesheet.png?1625128582786';
$ico-creba: (329px, 54px, -329px, -54px, 95px, 20px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-creba', );
$ico-desplega-name: 'ico-desplega';
$ico-desplega-x: 192px;
$ico-desplega-y: 171px;
$ico-desplega-offset-x: -192px;
$ico-desplega-offset-y: -171px;
$ico-desplega-width: 33px;
$ico-desplega-height: 33px;
$ico-desplega-total-width: 468px;
$ico-desplega-total-height: 301px;
$ico-desplega-image: 'img/spritesheet.png?1625128582786';
$ico-desplega: (192px, 171px, -192px, -171px, 33px, 33px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-desplega', );
$ico-document-doc-name: 'ico-document-doc';
$ico-document-doc-x: 255px;
$ico-document-doc-y: 224px;
$ico-document-doc-offset-x: -255px;
$ico-document-doc-offset-y: -224px;
$ico-document-doc-width: 30px;
$ico-document-doc-height: 31px;
$ico-document-doc-total-width: 468px;
$ico-document-doc-total-height: 301px;
$ico-document-doc-image: 'img/spritesheet.png?1625128582786';
$ico-document-doc: (255px, 224px, -255px, -224px, 30px, 31px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-document-doc', );
$ico-documents-name: 'ico-documents';
$ico-documents-x: 31px;
$ico-documents-y: 264px;
$ico-documents-offset-x: -31px;
$ico-documents-offset-y: -264px;
$ico-documents-width: 26px;
$ico-documents-height: 35px;
$ico-documents-total-width: 468px;
$ico-documents-total-height: 301px;
$ico-documents-image: 'img/spritesheet.png?1625128582786';
$ico-documents: (31px, 264px, -31px, -264px, 26px, 35px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-documents', );
$ico-donacions-llegat-name: 'ico-donacions-llegat';
$ico-donacions-llegat-x: 329px;
$ico-donacions-llegat-y: 16px;
$ico-donacions-llegat-offset-x: -329px;
$ico-donacions-llegat-offset-y: -16px;
$ico-donacions-llegat-width: 82px;
$ico-donacions-llegat-height: 38px;
$ico-donacions-llegat-total-width: 468px;
$ico-donacions-llegat-total-height: 301px;
$ico-donacions-llegat-image: 'img/spritesheet.png?1625128582786';
$ico-donacions-llegat: (329px, 16px, -329px, -16px, 82px, 38px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donacions-llegat', );
$ico-donacions-mevamanera-name: 'ico-donacions-mevamanera';
$ico-donacions-mevamanera-x: 228px;
$ico-donacions-mevamanera-y: 87px;
$ico-donacions-mevamanera-offset-x: -228px;
$ico-donacions-mevamanera-offset-y: -87px;
$ico-donacions-mevamanera-width: 49px;
$ico-donacions-mevamanera-height: 63px;
$ico-donacions-mevamanera-total-width: 468px;
$ico-donacions-mevamanera-total-height: 301px;
$ico-donacions-mevamanera-image: 'img/spritesheet.png?1625128582786';
$ico-donacions-mevamanera: (228px, 87px, -228px, -87px, 49px, 63px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donacions-mevamanera', );
$ico-donacions-periodica-name: 'ico-donacions-periodica';
$ico-donacions-periodica-x: 277px;
$ico-donacions-periodica-y: 87px;
$ico-donacions-periodica-offset-x: -277px;
$ico-donacions-periodica-offset-y: -87px;
$ico-donacions-periodica-width: 85px;
$ico-donacions-periodica-height: 36px;
$ico-donacions-periodica-total-width: 468px;
$ico-donacions-periodica-total-height: 301px;
$ico-donacions-periodica-image: 'img/spritesheet.png?1625128582786';
$ico-donacions-periodica: (277px, 87px, -277px, -87px, 85px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donacions-periodica', );
$ico-donacions-puntual-name: 'ico-donacions-puntual';
$ico-donacions-puntual-x: 80px;
$ico-donacions-puntual-y: 224px;
$ico-donacions-puntual-offset-x: -80px;
$ico-donacions-puntual-offset-y: -224px;
$ico-donacions-puntual-width: 48px;
$ico-donacions-puntual-height: 33px;
$ico-donacions-puntual-total-width: 468px;
$ico-donacions-puntual-total-height: 301px;
$ico-donacions-puntual-image: 'img/spritesheet.png?1625128582786';
$ico-donacions-puntual: (80px, 224px, -80px, -224px, 48px, 33px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donacions-puntual', );
$ico-donacions-voluntariat-name: 'ico-donacions-voluntariat';
$ico-donacions-voluntariat-x: 228px;
$ico-donacions-voluntariat-y: 150px;
$ico-donacions-voluntariat-offset-x: -228px;
$ico-donacions-voluntariat-offset-y: -150px;
$ico-donacions-voluntariat-width: 57px;
$ico-donacions-voluntariat-height: 52px;
$ico-donacions-voluntariat-total-width: 468px;
$ico-donacions-voluntariat-total-height: 301px;
$ico-donacions-voluntariat-image: 'img/spritesheet.png?1625128582786';
$ico-donacions-voluntariat: (228px, 150px, -228px, -150px, 57px, 52px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donacions-voluntariat', );
$ico-donate-boto-lg-name: 'ico-donate-boto-lg';
$ico-donate-boto-lg-x: 277px;
$ico-donate-boto-lg-y: 123px;
$ico-donate-boto-lg-offset-x: -277px;
$ico-donate-boto-lg-offset-y: -123px;
$ico-donate-boto-lg-width: 36px;
$ico-donate-boto-lg-height: 25px;
$ico-donate-boto-lg-total-width: 468px;
$ico-donate-boto-lg-total-height: 301px;
$ico-donate-boto-lg-image: 'img/spritesheet.png?1625128582786';
$ico-donate-boto-lg: (277px, 123px, -277px, -123px, 36px, 25px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donate-boto-lg', );
$ico-donate-boto-name: 'ico-donate-boto';
$ico-donate-boto-x: 313px;
$ico-donate-boto-y: 123px;
$ico-donate-boto-offset-x: -313px;
$ico-donate-boto-offset-y: -123px;
$ico-donate-boto-width: 29px;
$ico-donate-boto-height: 21px;
$ico-donate-boto-total-width: 468px;
$ico-donate-boto-total-height: 301px;
$ico-donate-boto-image: 'img/spritesheet.png?1625128582786';
$ico-donate-boto: (313px, 123px, -313px, -123px, 29px, 21px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-donate-boto', );
$ico-email-name: 'ico-email';
$ico-email-x: 404px;
$ico-email-y: 123px;
$ico-email-offset-x: -404px;
$ico-email-offset-y: -123px;
$ico-email-width: 18px;
$ico-email-height: 12px;
$ico-email-total-width: 468px;
$ico-email-total-height: 301px;
$ico-email-image: 'img/spritesheet.png?1625128582786';
$ico-email: (404px, 123px, -404px, -123px, 18px, 12px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-email', );
$ico-events-home-name: 'ico-events-home';
$ico-events-home-x: 362px;
$ico-events-home-y: 87px;
$ico-events-home-offset-x: -362px;
$ico-events-home-offset-y: -87px;
$ico-events-home-width: 49px;
$ico-events-home-height: 36px;
$ico-events-home-total-width: 468px;
$ico-events-home-total-height: 301px;
$ico-events-home-image: 'img/spritesheet.png?1625128582786';
$ico-events-home: (362px, 87px, -362px, -87px, 49px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-events-home', );
$ico-groups-name: 'ico-groups';
$ico-groups-x: 0px;
$ico-groups-y: 171px;
$ico-groups-offset-x: 0px;
$ico-groups-offset-y: -171px;
$ico-groups-width: 94px;
$ico-groups-height: 53px;
$ico-groups-total-width: 468px;
$ico-groups-total-height: 301px;
$ico-groups-image: 'img/spritesheet.png?1625128582786';
$ico-groups: (0px, 171px, 0px, -171px, 94px, 53px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-groups', );
$ico-investigators-name: 'ico-investigators';
$ico-investigators-x: 228px;
$ico-investigators-y: 16px;
$ico-investigators-offset-x: -228px;
$ico-investigators-offset-y: -16px;
$ico-investigators-width: 51px;
$ico-investigators-height: 71px;
$ico-investigators-total-width: 468px;
$ico-investigators-total-height: 301px;
$ico-investigators-image: 'img/spritesheet.png?1625128582786';
$ico-investigators: (228px, 16px, -228px, -16px, 51px, 71px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-investigators', );
$ico-involucrat-name: 'ico-involucrat';
$ico-involucrat-x: 337px;
$ico-involucrat-y: 150px;
$ico-involucrat-offset-x: -337px;
$ico-involucrat-offset-y: -150px;
$ico-involucrat-width: 62px;
$ico-involucrat-height: 42px;
$ico-involucrat-total-width: 468px;
$ico-involucrat-total-height: 301px;
$ico-involucrat-image: 'img/spritesheet.png?1625128582786';
$ico-involucrat: (337px, 150px, -337px, -150px, 62px, 42px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-involucrat', );
$ico-microscopi-research-name: 'ico-microscopi-research';
$ico-microscopi-research-x: 0px;
$ico-microscopi-research-y: 264px;
$ico-microscopi-research-offset-x: 0px;
$ico-microscopi-research-offset-y: -264px;
$ico-microscopi-research-width: 31px;
$ico-microscopi-research-height: 37px;
$ico-microscopi-research-total-width: 468px;
$ico-microscopi-research-total-height: 301px;
$ico-microscopi-research-image: 'img/spritesheet.png?1625128582786';
$ico-microscopi-research: (0px, 264px, 0px, -264px, 31px, 37px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-microscopi-research', );
$ico-news-home-name: 'ico-news-home';
$ico-news-home-x: 399px;
$ico-news-home-y: 150px;
$ico-news-home-offset-x: -399px;
$ico-news-home-offset-y: -150px;
$ico-news-home-width: 49px;
$ico-news-home-height: 36px;
$ico-news-home-total-width: 468px;
$ico-news-home-total-height: 301px;
$ico-news-home-image: 'img/spritesheet.png?1625128582786';
$ico-news-home: (399px, 150px, -399px, -150px, 49px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-news-home', );
$ico-news-name: 'ico-news';
$ico-news-x: 411px;
$ico-news-y: 16px;
$ico-news-offset-x: -411px;
$ico-news-offset-y: -16px;
$ico-news-width: 49px;
$ico-news-height: 36px;
$ico-news-total-width: 468px;
$ico-news-total-height: 301px;
$ico-news-image: 'img/spritesheet.png?1625128582786';
$ico-news: (411px, 16px, -411px, -16px, 49px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-news', );
$ico-plataform-services-name: 'ico-plataform-services';
$ico-plataform-services-x: 160px;
$ico-plataform-services-y: 0px;
$ico-plataform-services-offset-x: -160px;
$ico-plataform-services-offset-y: 0px;
$ico-plataform-services-width: 68px;
$ico-plataform-services-height: 92px;
$ico-plataform-services-total-width: 468px;
$ico-plataform-services-total-height: 301px;
$ico-plataform-services-image: 'img/spritesheet.png?1625128582786';
$ico-plataform-services: (160px, 0px, -160px, 0px, 68px, 92px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-plataform-services', );
$ico-plega-name: 'ico-plega';
$ico-plega-x: 128px;
$ico-plega-y: 224px;
$ico-plega-offset-x: -128px;
$ico-plega-offset-y: -224px;
$ico-plega-width: 33px;
$ico-plega-height: 33px;
$ico-plega-total-width: 468px;
$ico-plega-total-height: 301px;
$ico-plega-image: 'img/spritesheet.png?1625128582786';
$ico-plega: (128px, 224px, -128px, -224px, 33px, 33px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-plega', );
$ico-publicacio-destacada-name: 'ico-publicacio-destacada';
$ico-publicacio-destacada-x: 279px;
$ico-publicacio-destacada-y: 16px;
$ico-publicacio-destacada-offset-x: -279px;
$ico-publicacio-destacada-offset-y: -16px;
$ico-publicacio-destacada-width: 50px;
$ico-publicacio-destacada-height: 64px;
$ico-publicacio-destacada-total-width: 468px;
$ico-publicacio-destacada-total-height: 301px;
$ico-publicacio-destacada-image: 'img/spritesheet.png?1625128582786';
$ico-publicacio-destacada: (279px, 16px, -279px, -16px, 50px, 64px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-publicacio-destacada', );
$ico-research-name: 'ico-research';
$ico-research-x: 0px;
$ico-research-y: 0px;
$ico-research-offset-x: 0px;
$ico-research-offset-y: 0px;
$ico-research-width: 160px;
$ico-research-height: 171px;
$ico-research-total-width: 468px;
$ico-research-total-height: 301px;
$ico-research-image: 'img/spritesheet.png?1625128582786';
$ico-research: (0px, 0px, 0px, 0px, 160px, 171px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-research', );
$ico-training-home-name: 'ico-training-home';
$ico-training-home-x: 411px;
$ico-training-home-y: 87px;
$ico-training-home-offset-x: -411px;
$ico-training-home-offset-y: -87px;
$ico-training-home-width: 49px;
$ico-training-home-height: 36px;
$ico-training-home-total-width: 468px;
$ico-training-home-total-height: 301px;
$ico-training-home-image: 'img/spritesheet.png?1625128582786';
$ico-training-home: (411px, 87px, -411px, -87px, 49px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-training-home', );
$ico-training-name: 'ico-training';
$ico-training-x: 94px;
$ico-training-y: 171px;
$ico-training-offset-x: -94px;
$ico-training-offset-y: -171px;
$ico-training-width: 49px;
$ico-training-height: 36px;
$ico-training-total-width: 468px;
$ico-training-total-height: 301px;
$ico-training-image: 'img/spritesheet.png?1625128582786';
$ico-training: (94px, 171px, -94px, -171px, 49px, 36px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-training', );
$ico-web-name: 'ico-web';
$ico-web-x: 405px;
$ico-web-y: 224px;
$ico-web-offset-x: -405px;
$ico-web-offset-y: -224px;
$ico-web-width: 30px;
$ico-web-height: 30px;
$ico-web-total-width: 468px;
$ico-web-total-height: 301px;
$ico-web-image: 'img/spritesheet.png?1625128582786';
$ico-web: (405px, 224px, -405px, -224px, 30px, 30px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ico-web', );
$icon-close-name: 'icon-close';
$icon-close-x: 0px;
$icon-close-y: 224px;
$icon-close-offset-x: 0px;
$icon-close-offset-y: -224px;
$icon-close-width: 40px;
$icon-close-height: 40px;
$icon-close-total-width: 468px;
$icon-close-total-height: 301px;
$icon-close-image: 'img/spritesheet.png?1625128582786';
$icon-close: (0px, 224px, 0px, -224px, 40px, 40px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'icon-close', );
$icon-download-name: 'icon-download';
$icon-download-x: 424px;
$icon-download-y: 54px;
$icon-download-offset-x: -424px;
$icon-download-offset-y: -54px;
$icon-download-width: 30px;
$icon-download-height: 20px;
$icon-download-total-width: 468px;
$icon-download-total-height: 301px;
$icon-download-image: 'img/spritesheet.png?1625128582786';
$icon-download: (424px, 54px, -424px, -54px, 30px, 20px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'icon-download', );
$icon-enllacos-pdf-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-x: 204px;
$icon-enllacos-pdf-y: 148px;
$icon-enllacos-pdf-offset-x: -204px;
$icon-enllacos-pdf-offset-y: -148px;
$icon-enllacos-pdf-width: 22px;
$icon-enllacos-pdf-height: 23px;
$icon-enllacos-pdf-total-width: 468px;
$icon-enllacos-pdf-total-height: 301px;
$icon-enllacos-pdf-image: 'img/spritesheet.png?1625128582786';
$icon-enllacos-pdf: (204px, 148px, -204px, -148px, 22px, 23px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'icon-enllacos-pdf', );
$icon-enllacos-name: 'icon-enllacos';
$icon-enllacos-x: 454px;
$icon-enllacos-y: 54px;
$icon-enllacos-offset-x: -454px;
$icon-enllacos-offset-y: -54px;
$icon-enllacos-width: 11px;
$icon-enllacos-height: 18px;
$icon-enllacos-total-width: 468px;
$icon-enllacos-total-height: 301px;
$icon-enllacos-image: 'img/spritesheet.png?1625128582786';
$icon-enllacos: (454px, 54px, -454px, -54px, 11px, 18px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'icon-enllacos', );
$icon-link-name: 'icon-link';
$icon-link-x: 160px;
$icon-link-y: 148px;
$icon-link-offset-x: -160px;
$icon-link-offset-y: -148px;
$icon-link-width: 22px;
$icon-link-height: 23px;
$icon-link-total-width: 468px;
$icon-link-total-height: 301px;
$icon-link-image: 'img/spritesheet.png?1625128582786';
$icon-link: (160px, 148px, -160px, -148px, 22px, 23px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'icon-link', );
$icon-menu-small-name: 'icon-menu-small';
$icon-menu-small-x: 40px;
$icon-menu-small-y: 224px;
$icon-menu-small-offset-x: -40px;
$icon-menu-small-offset-y: -224px;
$icon-menu-small-width: 40px;
$icon-menu-small-height: 40px;
$icon-menu-small-total-width: 468px;
$icon-menu-small-total-height: 301px;
$icon-menu-small-image: 'img/spritesheet.png?1625128582786';
$icon-menu-small: (40px, 224px, -40px, -224px, 40px, 40px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'icon-menu-small', );
$large-slider-endavant-name: 'large-slider-endavant';
$large-slider-endavant-x: 435px;
$large-slider-endavant-y: 224px;
$large-slider-endavant-offset-x: -435px;
$large-slider-endavant-offset-y: -224px;
$large-slider-endavant-width: 30px;
$large-slider-endavant-height: 30px;
$large-slider-endavant-total-width: 468px;
$large-slider-endavant-total-height: 301px;
$large-slider-endavant-image: 'img/spritesheet.png?1625128582786';
$large-slider-endavant: (435px, 224px, -435px, -224px, 30px, 30px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'large-slider-endavant', );
$large-slider-enrera-name: 'large-slider-enrera';
$large-slider-enrera-x: 375px;
$large-slider-enrera-y: 224px;
$large-slider-enrera-offset-x: -375px;
$large-slider-enrera-offset-y: -224px;
$large-slider-enrera-width: 30px;
$large-slider-enrera-height: 30px;
$large-slider-enrera-total-width: 468px;
$large-slider-enrera-total-height: 301px;
$large-slider-enrera-image: 'img/spritesheet.png?1625128582786';
$large-slider-enrera: (375px, 224px, -375px, -224px, 30px, 30px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'large-slider-enrera', );
$logo-linkedin-name: 'logo-linkedin';
$logo-linkedin-x: 193px;
$logo-linkedin-y: 224px;
$logo-linkedin-offset-x: -193px;
$logo-linkedin-offset-y: -224px;
$logo-linkedin-width: 32px;
$logo-linkedin-height: 32px;
$logo-linkedin-total-width: 468px;
$logo-linkedin-total-height: 301px;
$logo-linkedin-image: 'img/spritesheet.png?1625128582786';
$logo-linkedin: (193px, 224px, -193px, -224px, 32px, 32px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'logo-linkedin', );
$logo-twitter-name: 'logo-twitter';
$logo-twitter-x: 161px;
$logo-twitter-y: 224px;
$logo-twitter-offset-x: -161px;
$logo-twitter-offset-y: -224px;
$logo-twitter-width: 32px;
$logo-twitter-height: 32px;
$logo-twitter-total-width: 468px;
$logo-twitter-total-height: 301px;
$logo-twitter-image: 'img/spritesheet.png?1625128582786';
$logo-twitter: (161px, 224px, -161px, -224px, 32px, 32px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'logo-twitter', );
$lupa-cercador-name: 'lupa-cercador';
$lupa-cercador-x: 369px;
$lupa-cercador-y: 123px;
$lupa-cercador-offset-x: -369px;
$lupa-cercador-offset-y: -123px;
$lupa-cercador-width: 17px;
$lupa-cercador-height: 17px;
$lupa-cercador-total-width: 468px;
$lupa-cercador-total-height: 301px;
$lupa-cercador-image: 'img/spritesheet.png?1625128582786';
$lupa-cercador: (369px, 123px, -369px, -123px, 17px, 17px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'lupa-cercador', );
$ombra-caixeta-lateral-name: 'ombra-caixeta-lateral';
$ombra-caixeta-lateral-x: 228px;
$ombra-caixeta-lateral-y: 0px;
$ombra-caixeta-lateral-offset-x: -228px;
$ombra-caixeta-lateral-offset-y: 0px;
$ombra-caixeta-lateral-width: 240px;
$ombra-caixeta-lateral-height: 16px;
$ombra-caixeta-lateral-total-width: 468px;
$ombra-caixeta-lateral-total-height: 301px;
$ombra-caixeta-lateral-image: 'img/spritesheet.png?1625128582786';
$ombra-caixeta-lateral: (228px, 0px, -228px, 0px, 240px, 16px, 468px, 301px, 'img/spritesheet.png?1625128582786', 'ombra-caixeta-lateral', );
$compartir-copiar-link-2x-name: 'compartir-copiar-link@2x';
$compartir-copiar-link-2x-x: 406px;
$compartir-copiar-link-2x-y: 232px;
$compartir-copiar-link-2x-offset-x: -406px;
$compartir-copiar-link-2x-offset-y: -232px;
$compartir-copiar-link-2x-width: 46px;
$compartir-copiar-link-2x-height: 46px;
$compartir-copiar-link-2x-total-width: 936px;
$compartir-copiar-link-2x-total-height: 602px;
$compartir-copiar-link-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-copiar-link-2x: (406px, 232px, -406px, -232px, 46px, 46px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-copiar-link@2x', );
$compartir-correu-2x-name: 'compartir-correu@2x';
$compartir-correu-2x-x: 684px;
$compartir-correu-2x-y: 246px;
$compartir-correu-2x-offset-x: -684px;
$compartir-correu-2x-offset-y: -246px;
$compartir-correu-2x-width: 54px;
$compartir-correu-2x-height: 36px;
$compartir-correu-2x-total-width: 936px;
$compartir-correu-2x-total-height: 602px;
$compartir-correu-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-correu-2x: (684px, 246px, -684px, -246px, 54px, 36px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-correu@2x', );
$compartir-facebook-2x-name: 'compartir-facebook@2x';
$compartir-facebook-2x-x: 506px;
$compartir-facebook-2x-y: 404px;
$compartir-facebook-2x-offset-x: -506px;
$compartir-facebook-2x-offset-y: -404px;
$compartir-facebook-2x-width: 44px;
$compartir-facebook-2x-height: 44px;
$compartir-facebook-2x-total-width: 936px;
$compartir-facebook-2x-total-height: 602px;
$compartir-facebook-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-facebook-2x: (506px, 404px, -506px, -404px, 44px, 44px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-facebook@2x', );
$compartir-google-2x-name: 'compartir-google@2x';
$compartir-google-2x-x: 406px;
$compartir-google-2x-y: 184px;
$compartir-google-2x-offset-x: -406px;
$compartir-google-2x-offset-y: -184px;
$compartir-google-2x-width: 48px;
$compartir-google-2x-height: 48px;
$compartir-google-2x-total-width: 936px;
$compartir-google-2x-total-height: 602px;
$compartir-google-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-google-2x: (406px, 184px, -406px, -184px, 48px, 48px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-google@2x', );
$compartir-link-2x-name: 'compartir-link@2x';
$compartir-link-2x-x: 638px;
$compartir-link-2x-y: 404px;
$compartir-link-2x-offset-x: -638px;
$compartir-link-2x-offset-y: -404px;
$compartir-link-2x-width: 42px;
$compartir-link-2x-height: 42px;
$compartir-link-2x-total-width: 936px;
$compartir-link-2x-total-height: 602px;
$compartir-link-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-link-2x: (638px, 404px, -638px, -404px, 42px, 42px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-link@2x', );
$compartir-linkedin-2x-name: 'compartir-linkedin@2x';
$compartir-linkedin-2x-x: 364px;
$compartir-linkedin-2x-y: 296px;
$compartir-linkedin-2x-offset-x: -364px;
$compartir-linkedin-2x-offset-y: -296px;
$compartir-linkedin-2x-width: 44px;
$compartir-linkedin-2x-height: 46px;
$compartir-linkedin-2x-total-width: 936px;
$compartir-linkedin-2x-total-height: 602px;
$compartir-linkedin-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-linkedin-2x: (364px, 296px, -364px, -296px, 44px, 46px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-linkedin@2x', );
$compartir-twitter-2x-name: 'compartir-twitter@2x';
$compartir-twitter-2x-x: 456px;
$compartir-twitter-2x-y: 404px;
$compartir-twitter-2x-offset-x: -456px;
$compartir-twitter-2x-offset-y: -404px;
$compartir-twitter-2x-width: 50px;
$compartir-twitter-2x-height: 44px;
$compartir-twitter-2x-total-width: 936px;
$compartir-twitter-2x-total-height: 602px;
$compartir-twitter-2x-image: 'img/spritesheet@2x.png?1625128582786';
$compartir-twitter-2x: (456px, 404px, -456px, -404px, 50px, 44px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'compartir-twitter@2x', );
$fletxa-crowdfunding-anterior-2x-name: 'fletxa-crowdfunding-anterior@2x';
$fletxa-crowdfunding-anterior-2x-x: 550px;
$fletxa-crowdfunding-anterior-2x-y: 404px;
$fletxa-crowdfunding-anterior-2x-offset-x: -550px;
$fletxa-crowdfunding-anterior-2x-offset-y: -404px;
$fletxa-crowdfunding-anterior-2x-width: 44px;
$fletxa-crowdfunding-anterior-2x-height: 42px;
$fletxa-crowdfunding-anterior-2x-total-width: 936px;
$fletxa-crowdfunding-anterior-2x-total-height: 602px;
$fletxa-crowdfunding-anterior-2x-image: 'img/spritesheet@2x.png?1625128582786';
$fletxa-crowdfunding-anterior-2x: (550px, 404px, -550px, -404px, 44px, 42px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'fletxa-crowdfunding-anterior@2x', );
$fletxa-crowdfunding-seguent-2x-name: 'fletxa-crowdfunding-seguent@2x';
$fletxa-crowdfunding-seguent-2x-x: 594px;
$fletxa-crowdfunding-seguent-2x-y: 404px;
$fletxa-crowdfunding-seguent-2x-offset-x: -594px;
$fletxa-crowdfunding-seguent-2x-offset-y: -404px;
$fletxa-crowdfunding-seguent-2x-width: 44px;
$fletxa-crowdfunding-seguent-2x-height: 42px;
$fletxa-crowdfunding-seguent-2x-total-width: 936px;
$fletxa-crowdfunding-seguent-2x-total-height: 602px;
$fletxa-crowdfunding-seguent-2x-image: 'img/spritesheet@2x.png?1625128582786';
$fletxa-crowdfunding-seguent-2x: (594px, 404px, -594px, -404px, 44px, 42px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'fletxa-crowdfunding-seguent@2x', );
$ico-404-2x-name: 'ico-404@2x';
$ico-404-2x-x: 570px;
$ico-404-2x-y: 300px;
$ico-404-2x-offset-x: -570px;
$ico-404-2x-offset-y: -300px;
$ico-404-2x-width: 104px;
$ico-404-2x-height: 104px;
$ico-404-2x-total-width: 936px;
$ico-404-2x-total-height: 602px;
$ico-404-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-404-2x: (570px, 300px, -570px, -300px, 104px, 104px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-404@2x', );
$ico-articles-2x-name: 'ico-articles@2x';
$ico-articles-2x-x: 320px;
$ico-articles-2x-y: 184px;
$ico-articles-2x-offset-x: -320px;
$ico-articles-2x-offset-y: -184px;
$ico-articles-2x-width: 86px;
$ico-articles-2x-height: 112px;
$ico-articles-2x-total-width: 936px;
$ico-articles-2x-total-height: 602px;
$ico-articles-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-articles-2x: (320px, 184px, -320px, -184px, 86px, 112px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-articles@2x', );
$ico-arxiu-jpg-2x-name: 'ico-arxiu-jpg@2x';
$ico-arxiu-jpg-2x-x: 172px;
$ico-arxiu-jpg-2x-y: 528px;
$ico-arxiu-jpg-2x-offset-x: -172px;
$ico-arxiu-jpg-2x-offset-y: -528px;
$ico-arxiu-jpg-2x-width: 58px;
$ico-arxiu-jpg-2x-height: 62px;
$ico-arxiu-jpg-2x-total-width: 936px;
$ico-arxiu-jpg-2x-total-height: 602px;
$ico-arxiu-jpg-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-arxiu-jpg-2x: (172px, 528px, -172px, -528px, 58px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-arxiu-jpg@2x', );
$ico-arxiu-keynote-2x-name: 'ico-arxiu-keynote@2x';
$ico-arxiu-keynote-2x-x: 690px;
$ico-arxiu-keynote-2x-y: 448px;
$ico-arxiu-keynote-2x-offset-x: -690px;
$ico-arxiu-keynote-2x-offset-y: -448px;
$ico-arxiu-keynote-2x-width: 60px;
$ico-arxiu-keynote-2x-height: 62px;
$ico-arxiu-keynote-2x-total-width: 936px;
$ico-arxiu-keynote-2x-total-height: 602px;
$ico-arxiu-keynote-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-arxiu-keynote-2x: (690px, 448px, -690px, -448px, 60px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-arxiu-keynote@2x', );
$ico-arxiu-pdf-2x-name: 'ico-arxiu-pdf@2x';
$ico-arxiu-pdf-2x-x: 450px;
$ico-arxiu-pdf-2x-y: 448px;
$ico-arxiu-pdf-2x-offset-x: -450px;
$ico-arxiu-pdf-2x-offset-y: -448px;
$ico-arxiu-pdf-2x-width: 60px;
$ico-arxiu-pdf-2x-height: 62px;
$ico-arxiu-pdf-2x-total-width: 936px;
$ico-arxiu-pdf-2x-total-height: 602px;
$ico-arxiu-pdf-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-arxiu-pdf-2x: (450px, 448px, -450px, -448px, 60px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-arxiu-pdf@2x', );
$ico-arxiu-png-2x-name: 'ico-arxiu-png@2x';
$ico-arxiu-png-2x-x: 114px;
$ico-arxiu-png-2x-y: 528px;
$ico-arxiu-png-2x-offset-x: -114px;
$ico-arxiu-png-2x-offset-y: -528px;
$ico-arxiu-png-2x-width: 58px;
$ico-arxiu-png-2x-height: 62px;
$ico-arxiu-png-2x-total-width: 936px;
$ico-arxiu-png-2x-total-height: 602px;
$ico-arxiu-png-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-arxiu-png-2x: (114px, 528px, -114px, -528px, 58px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-arxiu-png@2x', );
$ico-arxiu-ppt-2x-name: 'ico-arxiu-ppt@2x';
$ico-arxiu-ppt-2x-x: 570px;
$ico-arxiu-ppt-2x-y: 448px;
$ico-arxiu-ppt-2x-offset-x: -570px;
$ico-arxiu-ppt-2x-offset-y: -448px;
$ico-arxiu-ppt-2x-width: 60px;
$ico-arxiu-ppt-2x-height: 62px;
$ico-arxiu-ppt-2x-total-width: 936px;
$ico-arxiu-ppt-2x-total-height: 602px;
$ico-arxiu-ppt-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-arxiu-ppt-2x: (570px, 448px, -570px, -448px, 60px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-arxiu-ppt@2x', );
$ico-arxiu-txt-2x-name: 'ico-arxiu-txt@2x';
$ico-arxiu-txt-2x-x: 630px;
$ico-arxiu-txt-2x-y: 448px;
$ico-arxiu-txt-2x-offset-x: -630px;
$ico-arxiu-txt-2x-offset-y: -448px;
$ico-arxiu-txt-2x-width: 60px;
$ico-arxiu-txt-2x-height: 62px;
$ico-arxiu-txt-2x-total-width: 936px;
$ico-arxiu-txt-2x-total-height: 602px;
$ico-arxiu-txt-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-arxiu-txt-2x: (630px, 448px, -630px, -448px, 60px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-arxiu-txt@2x', );
$ico-borsa-2x-name: 'ico-borsa@2x';
$ico-borsa-2x-x: 286px;
$ico-borsa-2x-y: 342px;
$ico-borsa-2x-offset-x: -286px;
$ico-borsa-2x-offset-y: -342px;
$ico-borsa-2x-width: 98px;
$ico-borsa-2x-height: 72px;
$ico-borsa-2x-total-width: 936px;
$ico-borsa-2x-total-height: 602px;
$ico-borsa-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-borsa-2x: (286px, 342px, -286px, -342px, 98px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-borsa@2x', );
$ico-correu-2x-name: 'ico-correu@2x';
$ico-correu-2x-x: 772px;
$ico-correu-2x-y: 246px;
$ico-correu-2x-offset-x: -772px;
$ico-correu-2x-offset-y: -246px;
$ico-correu-2x-width: 36px;
$ico-correu-2x-height: 26px;
$ico-correu-2x-total-width: 936px;
$ico-correu-2x-total-height: 602px;
$ico-correu-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-correu-2x: (772px, 246px, -772px, -246px, 36px, 26px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-correu@2x', );
$ico-creba-2x-name: 'ico-creba@2x';
$ico-creba-2x-x: 658px;
$ico-creba-2x-y: 108px;
$ico-creba-2x-offset-x: -658px;
$ico-creba-2x-offset-y: -108px;
$ico-creba-2x-width: 190px;
$ico-creba-2x-height: 40px;
$ico-creba-2x-total-width: 936px;
$ico-creba-2x-total-height: 602px;
$ico-creba-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-creba-2x: (658px, 108px, -658px, -108px, 190px, 40px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-creba@2x', );
$ico-desplega-2x-name: 'ico-desplega@2x';
$ico-desplega-2x-x: 384px;
$ico-desplega-2x-y: 342px;
$ico-desplega-2x-offset-x: -384px;
$ico-desplega-2x-offset-y: -342px;
$ico-desplega-2x-width: 66px;
$ico-desplega-2x-height: 66px;
$ico-desplega-2x-total-width: 936px;
$ico-desplega-2x-total-height: 602px;
$ico-desplega-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-desplega-2x: (384px, 342px, -384px, -342px, 66px, 66px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-desplega@2x', );
$ico-document-doc-2x-name: 'ico-document-doc@2x';
$ico-document-doc-2x-x: 510px;
$ico-document-doc-2x-y: 448px;
$ico-document-doc-2x-offset-x: -510px;
$ico-document-doc-2x-offset-y: -448px;
$ico-document-doc-2x-width: 60px;
$ico-document-doc-2x-height: 62px;
$ico-document-doc-2x-total-width: 936px;
$ico-document-doc-2x-total-height: 602px;
$ico-document-doc-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-document-doc-2x: (510px, 448px, -510px, -448px, 60px, 62px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-document-doc@2x', );
$ico-documents-2x-name: 'ico-documents@2x';
$ico-documents-2x-x: 62px;
$ico-documents-2x-y: 528px;
$ico-documents-2x-offset-x: -62px;
$ico-documents-2x-offset-y: -528px;
$ico-documents-2x-width: 52px;
$ico-documents-2x-height: 70px;
$ico-documents-2x-total-width: 936px;
$ico-documents-2x-total-height: 602px;
$ico-documents-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-documents-2x: (62px, 528px, -62px, -528px, 52px, 70px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-documents@2x', );
$ico-donacions-llegat-2x-name: 'ico-donacions-llegat@2x';
$ico-donacions-llegat-2x-x: 658px;
$ico-donacions-llegat-2x-y: 32px;
$ico-donacions-llegat-2x-offset-x: -658px;
$ico-donacions-llegat-2x-offset-y: -32px;
$ico-donacions-llegat-2x-width: 164px;
$ico-donacions-llegat-2x-height: 76px;
$ico-donacions-llegat-2x-total-width: 936px;
$ico-donacions-llegat-2x-total-height: 602px;
$ico-donacions-llegat-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donacions-llegat-2x: (658px, 32px, -658px, -32px, 164px, 76px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donacions-llegat@2x', );
$ico-donacions-mevamanera-2x-name: 'ico-donacions-mevamanera@2x';
$ico-donacions-mevamanera-2x-x: 456px;
$ico-donacions-mevamanera-2x-y: 174px;
$ico-donacions-mevamanera-2x-offset-x: -456px;
$ico-donacions-mevamanera-2x-offset-y: -174px;
$ico-donacions-mevamanera-2x-width: 98px;
$ico-donacions-mevamanera-2x-height: 126px;
$ico-donacions-mevamanera-2x-total-width: 936px;
$ico-donacions-mevamanera-2x-total-height: 602px;
$ico-donacions-mevamanera-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donacions-mevamanera-2x: (456px, 174px, -456px, -174px, 98px, 126px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donacions-mevamanera@2x', );
$ico-donacions-periodica-2x-name: 'ico-donacions-periodica@2x';
$ico-donacions-periodica-2x-x: 554px;
$ico-donacions-periodica-2x-y: 174px;
$ico-donacions-periodica-2x-offset-x: -554px;
$ico-donacions-periodica-2x-offset-y: -174px;
$ico-donacions-periodica-2x-width: 170px;
$ico-donacions-periodica-2x-height: 72px;
$ico-donacions-periodica-2x-total-width: 936px;
$ico-donacions-periodica-2x-total-height: 602px;
$ico-donacions-periodica-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donacions-periodica-2x: (554px, 174px, -554px, -174px, 170px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donacions-periodica@2x', );
$ico-donacions-puntual-2x-name: 'ico-donacions-puntual@2x';
$ico-donacions-puntual-2x-x: 160px;
$ico-donacions-puntual-2x-y: 448px;
$ico-donacions-puntual-2x-offset-x: -160px;
$ico-donacions-puntual-2x-offset-y: -448px;
$ico-donacions-puntual-2x-width: 96px;
$ico-donacions-puntual-2x-height: 66px;
$ico-donacions-puntual-2x-total-width: 936px;
$ico-donacions-puntual-2x-total-height: 602px;
$ico-donacions-puntual-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donacions-puntual-2x: (160px, 448px, -160px, -448px, 96px, 66px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donacions-puntual@2x', );
$ico-donacions-voluntariat-2x-name: 'ico-donacions-voluntariat@2x';
$ico-donacions-voluntariat-2x-x: 456px;
$ico-donacions-voluntariat-2x-y: 300px;
$ico-donacions-voluntariat-2x-offset-x: -456px;
$ico-donacions-voluntariat-2x-offset-y: -300px;
$ico-donacions-voluntariat-2x-width: 114px;
$ico-donacions-voluntariat-2x-height: 104px;
$ico-donacions-voluntariat-2x-total-width: 936px;
$ico-donacions-voluntariat-2x-total-height: 602px;
$ico-donacions-voluntariat-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donacions-voluntariat-2x: (456px, 300px, -456px, -300px, 114px, 104px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donacions-voluntariat@2x', );
$ico-donate-boto-lg-2x-name: 'ico-donate-boto-lg@2x';
$ico-donate-boto-lg-2x-x: 554px;
$ico-donate-boto-lg-2x-y: 246px;
$ico-donate-boto-lg-2x-offset-x: -554px;
$ico-donate-boto-lg-2x-offset-y: -246px;
$ico-donate-boto-lg-2x-width: 72px;
$ico-donate-boto-lg-2x-height: 50px;
$ico-donate-boto-lg-2x-total-width: 936px;
$ico-donate-boto-lg-2x-total-height: 602px;
$ico-donate-boto-lg-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donate-boto-lg-2x: (554px, 246px, -554px, -246px, 72px, 50px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donate-boto-lg@2x', );
$ico-donate-boto-2x-name: 'ico-donate-boto@2x';
$ico-donate-boto-2x-x: 626px;
$ico-donate-boto-2x-y: 246px;
$ico-donate-boto-2x-offset-x: -626px;
$ico-donate-boto-2x-offset-y: -246px;
$ico-donate-boto-2x-width: 58px;
$ico-donate-boto-2x-height: 42px;
$ico-donate-boto-2x-total-width: 936px;
$ico-donate-boto-2x-total-height: 602px;
$ico-donate-boto-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-donate-boto-2x: (626px, 246px, -626px, -246px, 58px, 42px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-donate-boto@2x', );
$ico-email-2x-name: 'ico-email@2x';
$ico-email-2x-x: 808px;
$ico-email-2x-y: 246px;
$ico-email-2x-offset-x: -808px;
$ico-email-2x-offset-y: -246px;
$ico-email-2x-width: 36px;
$ico-email-2x-height: 24px;
$ico-email-2x-total-width: 936px;
$ico-email-2x-total-height: 602px;
$ico-email-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-email-2x: (808px, 246px, -808px, -246px, 36px, 24px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-email@2x', );
$ico-events-home-2x-name: 'ico-events-home@2x';
$ico-events-home-2x-x: 724px;
$ico-events-home-2x-y: 174px;
$ico-events-home-2x-offset-x: -724px;
$ico-events-home-2x-offset-y: -174px;
$ico-events-home-2x-width: 98px;
$ico-events-home-2x-height: 72px;
$ico-events-home-2x-total-width: 936px;
$ico-events-home-2x-total-height: 602px;
$ico-events-home-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-events-home-2x: (724px, 174px, -724px, -174px, 98px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-events-home@2x', );
$ico-groups-2x-name: 'ico-groups@2x';
$ico-groups-2x-x: 0px;
$ico-groups-2x-y: 342px;
$ico-groups-2x-offset-x: 0px;
$ico-groups-2x-offset-y: -342px;
$ico-groups-2x-width: 188px;
$ico-groups-2x-height: 106px;
$ico-groups-2x-total-width: 936px;
$ico-groups-2x-total-height: 602px;
$ico-groups-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-groups-2x: (0px, 342px, 0px, -342px, 188px, 106px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-groups@2x', );
$ico-investigators-2x-name: 'ico-investigators@2x';
$ico-investigators-2x-x: 456px;
$ico-investigators-2x-y: 32px;
$ico-investigators-2x-offset-x: -456px;
$ico-investigators-2x-offset-y: -32px;
$ico-investigators-2x-width: 102px;
$ico-investigators-2x-height: 142px;
$ico-investigators-2x-total-width: 936px;
$ico-investigators-2x-total-height: 602px;
$ico-investigators-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-investigators-2x: (456px, 32px, -456px, -32px, 102px, 142px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-investigators@2x', );
$ico-involucrat-2x-name: 'ico-involucrat@2x';
$ico-involucrat-2x-x: 674px;
$ico-involucrat-2x-y: 300px;
$ico-involucrat-2x-offset-x: -674px;
$ico-involucrat-2x-offset-y: -300px;
$ico-involucrat-2x-width: 124px;
$ico-involucrat-2x-height: 84px;
$ico-involucrat-2x-total-width: 936px;
$ico-involucrat-2x-total-height: 602px;
$ico-involucrat-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-involucrat-2x: (674px, 300px, -674px, -300px, 124px, 84px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-involucrat@2x', );
$ico-microscopi-research-2x-name: 'ico-microscopi-research@2x';
$ico-microscopi-research-2x-x: 0px;
$ico-microscopi-research-2x-y: 528px;
$ico-microscopi-research-2x-offset-x: 0px;
$ico-microscopi-research-2x-offset-y: -528px;
$ico-microscopi-research-2x-width: 62px;
$ico-microscopi-research-2x-height: 74px;
$ico-microscopi-research-2x-total-width: 936px;
$ico-microscopi-research-2x-total-height: 602px;
$ico-microscopi-research-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-microscopi-research-2x: (0px, 528px, 0px, -528px, 62px, 74px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-microscopi-research@2x', );
$ico-news-home-2x-name: 'ico-news-home@2x';
$ico-news-home-2x-x: 798px;
$ico-news-home-2x-y: 300px;
$ico-news-home-2x-offset-x: -798px;
$ico-news-home-2x-offset-y: -300px;
$ico-news-home-2x-width: 98px;
$ico-news-home-2x-height: 72px;
$ico-news-home-2x-total-width: 936px;
$ico-news-home-2x-total-height: 602px;
$ico-news-home-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-news-home-2x: (798px, 300px, -798px, -300px, 98px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-news-home@2x', );
$ico-news-2x-name: 'ico-news@2x';
$ico-news-2x-x: 822px;
$ico-news-2x-y: 32px;
$ico-news-2x-offset-x: -822px;
$ico-news-2x-offset-y: -32px;
$ico-news-2x-width: 98px;
$ico-news-2x-height: 72px;
$ico-news-2x-total-width: 936px;
$ico-news-2x-total-height: 602px;
$ico-news-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-news-2x: (822px, 32px, -822px, -32px, 98px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-news@2x', );
$ico-plataform-services-2x-name: 'ico-plataform-services@2x';
$ico-plataform-services-2x-x: 320px;
$ico-plataform-services-2x-y: 0px;
$ico-plataform-services-2x-offset-x: -320px;
$ico-plataform-services-2x-offset-y: 0px;
$ico-plataform-services-2x-width: 136px;
$ico-plataform-services-2x-height: 184px;
$ico-plataform-services-2x-total-width: 936px;
$ico-plataform-services-2x-total-height: 602px;
$ico-plataform-services-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-plataform-services-2x: (320px, 0px, -320px, 0px, 136px, 184px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-plataform-services@2x', );
$ico-plega-2x-name: 'ico-plega@2x';
$ico-plega-2x-x: 256px;
$ico-plega-2x-y: 448px;
$ico-plega-2x-offset-x: -256px;
$ico-plega-2x-offset-y: -448px;
$ico-plega-2x-width: 66px;
$ico-plega-2x-height: 66px;
$ico-plega-2x-total-width: 936px;
$ico-plega-2x-total-height: 602px;
$ico-plega-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-plega-2x: (256px, 448px, -256px, -448px, 66px, 66px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-plega@2x', );
$ico-publicacio-destacada-2x-name: 'ico-publicacio-destacada@2x';
$ico-publicacio-destacada-2x-x: 558px;
$ico-publicacio-destacada-2x-y: 32px;
$ico-publicacio-destacada-2x-offset-x: -558px;
$ico-publicacio-destacada-2x-offset-y: -32px;
$ico-publicacio-destacada-2x-width: 100px;
$ico-publicacio-destacada-2x-height: 128px;
$ico-publicacio-destacada-2x-total-width: 936px;
$ico-publicacio-destacada-2x-total-height: 602px;
$ico-publicacio-destacada-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-publicacio-destacada-2x: (558px, 32px, -558px, -32px, 100px, 128px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-publicacio-destacada@2x', );
$ico-research-2x-name: 'ico-research@2x';
$ico-research-2x-x: 0px;
$ico-research-2x-y: 0px;
$ico-research-2x-offset-x: 0px;
$ico-research-2x-offset-y: 0px;
$ico-research-2x-width: 320px;
$ico-research-2x-height: 342px;
$ico-research-2x-total-width: 936px;
$ico-research-2x-total-height: 602px;
$ico-research-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-research-2x: (0px, 0px, 0px, 0px, 320px, 342px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-research@2x', );
$ico-training-home-2x-name: 'ico-training-home@2x';
$ico-training-home-2x-x: 822px;
$ico-training-home-2x-y: 174px;
$ico-training-home-2x-offset-x: -822px;
$ico-training-home-2x-offset-y: -174px;
$ico-training-home-2x-width: 98px;
$ico-training-home-2x-height: 72px;
$ico-training-home-2x-total-width: 936px;
$ico-training-home-2x-total-height: 602px;
$ico-training-home-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-training-home-2x: (822px, 174px, -822px, -174px, 98px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-training-home@2x', );
$ico-training-2x-name: 'ico-training@2x';
$ico-training-2x-x: 188px;
$ico-training-2x-y: 342px;
$ico-training-2x-offset-x: -188px;
$ico-training-2x-offset-y: -342px;
$ico-training-2x-width: 98px;
$ico-training-2x-height: 72px;
$ico-training-2x-total-width: 936px;
$ico-training-2x-total-height: 602px;
$ico-training-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-training-2x: (188px, 342px, -188px, -342px, 98px, 72px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-training@2x', );
$ico-web-2x-name: 'ico-web@2x';
$ico-web-2x-x: 810px;
$ico-web-2x-y: 448px;
$ico-web-2x-offset-x: -810px;
$ico-web-2x-offset-y: -448px;
$ico-web-2x-width: 60px;
$ico-web-2x-height: 60px;
$ico-web-2x-total-width: 936px;
$ico-web-2x-total-height: 602px;
$ico-web-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ico-web-2x: (810px, 448px, -810px, -448px, 60px, 60px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ico-web@2x', );
$icon-close-2x-name: 'icon-close@2x';
$icon-close-2x-x: 0px;
$icon-close-2x-y: 448px;
$icon-close-2x-offset-x: 0px;
$icon-close-2x-offset-y: -448px;
$icon-close-2x-width: 80px;
$icon-close-2x-height: 80px;
$icon-close-2x-total-width: 936px;
$icon-close-2x-total-height: 602px;
$icon-close-2x-image: 'img/spritesheet@2x.png?1625128582786';
$icon-close-2x: (0px, 448px, 0px, -448px, 80px, 80px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'icon-close@2x', );
$icon-download-2x-name: 'icon-download@2x';
$icon-download-2x-x: 848px;
$icon-download-2x-y: 108px;
$icon-download-2x-offset-x: -848px;
$icon-download-2x-offset-y: -108px;
$icon-download-2x-width: 60px;
$icon-download-2x-height: 40px;
$icon-download-2x-total-width: 936px;
$icon-download-2x-total-height: 602px;
$icon-download-2x-image: 'img/spritesheet@2x.png?1625128582786';
$icon-download-2x: (848px, 108px, -848px, -108px, 60px, 40px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'icon-download@2x', );
$icon-enllacos-pdf-2x-name: 'icon-enllacos-pdf@2x';
$icon-enllacos-pdf-2x-x: 408px;
$icon-enllacos-pdf-2x-y: 296px;
$icon-enllacos-pdf-2x-offset-x: -408px;
$icon-enllacos-pdf-2x-offset-y: -296px;
$icon-enllacos-pdf-2x-width: 44px;
$icon-enllacos-pdf-2x-height: 46px;
$icon-enllacos-pdf-2x-total-width: 936px;
$icon-enllacos-pdf-2x-total-height: 602px;
$icon-enllacos-pdf-2x-image: 'img/spritesheet@2x.png?1625128582786';
$icon-enllacos-pdf-2x: (408px, 296px, -408px, -296px, 44px, 46px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'icon-enllacos-pdf@2x', );
$icon-enllacos-2x-name: 'icon-enllacos@2x';
$icon-enllacos-2x-x: 908px;
$icon-enllacos-2x-y: 108px;
$icon-enllacos-2x-offset-x: -908px;
$icon-enllacos-2x-offset-y: -108px;
$icon-enllacos-2x-width: 22px;
$icon-enllacos-2x-height: 36px;
$icon-enllacos-2x-total-width: 936px;
$icon-enllacos-2x-total-height: 602px;
$icon-enllacos-2x-image: 'img/spritesheet@2x.png?1625128582786';
$icon-enllacos-2x: (908px, 108px, -908px, -108px, 22px, 36px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'icon-enllacos@2x', );
$icon-link-2x-name: 'icon-link@2x';
$icon-link-2x-x: 320px;
$icon-link-2x-y: 296px;
$icon-link-2x-offset-x: -320px;
$icon-link-2x-offset-y: -296px;
$icon-link-2x-width: 44px;
$icon-link-2x-height: 46px;
$icon-link-2x-total-width: 936px;
$icon-link-2x-total-height: 602px;
$icon-link-2x-image: 'img/spritesheet@2x.png?1625128582786';
$icon-link-2x: (320px, 296px, -320px, -296px, 44px, 46px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'icon-link@2x', );
$icon-menu-small-2x-name: 'icon-menu-small@2x';
$icon-menu-small-2x-x: 80px;
$icon-menu-small-2x-y: 448px;
$icon-menu-small-2x-offset-x: -80px;
$icon-menu-small-2x-offset-y: -448px;
$icon-menu-small-2x-width: 80px;
$icon-menu-small-2x-height: 80px;
$icon-menu-small-2x-total-width: 936px;
$icon-menu-small-2x-total-height: 602px;
$icon-menu-small-2x-image: 'img/spritesheet@2x.png?1625128582786';
$icon-menu-small-2x: (80px, 448px, -80px, -448px, 80px, 80px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'icon-menu-small@2x', );
$large-slider-endavant-2x-name: 'large-slider-endavant@2x';
$large-slider-endavant-2x-x: 870px;
$large-slider-endavant-2x-y: 448px;
$large-slider-endavant-2x-offset-x: -870px;
$large-slider-endavant-2x-offset-y: -448px;
$large-slider-endavant-2x-width: 60px;
$large-slider-endavant-2x-height: 60px;
$large-slider-endavant-2x-total-width: 936px;
$large-slider-endavant-2x-total-height: 602px;
$large-slider-endavant-2x-image: 'img/spritesheet@2x.png?1625128582786';
$large-slider-endavant-2x: (870px, 448px, -870px, -448px, 60px, 60px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'large-slider-endavant@2x', );
$large-slider-enrera-2x-name: 'large-slider-enrera@2x';
$large-slider-enrera-2x-x: 750px;
$large-slider-enrera-2x-y: 448px;
$large-slider-enrera-2x-offset-x: -750px;
$large-slider-enrera-2x-offset-y: -448px;
$large-slider-enrera-2x-width: 60px;
$large-slider-enrera-2x-height: 60px;
$large-slider-enrera-2x-total-width: 936px;
$large-slider-enrera-2x-total-height: 602px;
$large-slider-enrera-2x-image: 'img/spritesheet@2x.png?1625128582786';
$large-slider-enrera-2x: (750px, 448px, -750px, -448px, 60px, 60px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'large-slider-enrera@2x', );
$logo-linkedin-2x-name: 'logo-linkedin@2x';
$logo-linkedin-2x-x: 386px;
$logo-linkedin-2x-y: 448px;
$logo-linkedin-2x-offset-x: -386px;
$logo-linkedin-2x-offset-y: -448px;
$logo-linkedin-2x-width: 64px;
$logo-linkedin-2x-height: 64px;
$logo-linkedin-2x-total-width: 936px;
$logo-linkedin-2x-total-height: 602px;
$logo-linkedin-2x-image: 'img/spritesheet@2x.png?1625128582786';
$logo-linkedin-2x: (386px, 448px, -386px, -448px, 64px, 64px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'logo-linkedin@2x', );
$logo-twitter-2x-name: 'logo-twitter@2x';
$logo-twitter-2x-x: 322px;
$logo-twitter-2x-y: 448px;
$logo-twitter-2x-offset-x: -322px;
$logo-twitter-2x-offset-y: -448px;
$logo-twitter-2x-width: 64px;
$logo-twitter-2x-height: 64px;
$logo-twitter-2x-total-width: 936px;
$logo-twitter-2x-total-height: 602px;
$logo-twitter-2x-image: 'img/spritesheet@2x.png?1625128582786';
$logo-twitter-2x: (322px, 448px, -322px, -448px, 64px, 64px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'logo-twitter@2x', );
$lupa-cercador-2x-name: 'lupa-cercador@2x';
$lupa-cercador-2x-x: 738px;
$lupa-cercador-2x-y: 246px;
$lupa-cercador-2x-offset-x: -738px;
$lupa-cercador-2x-offset-y: -246px;
$lupa-cercador-2x-width: 34px;
$lupa-cercador-2x-height: 34px;
$lupa-cercador-2x-total-width: 936px;
$lupa-cercador-2x-total-height: 602px;
$lupa-cercador-2x-image: 'img/spritesheet@2x.png?1625128582786';
$lupa-cercador-2x: (738px, 246px, -738px, -246px, 34px, 34px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'lupa-cercador@2x', );
$ombra-caixeta-lateral-2x-name: 'ombra-caixeta-lateral@2x';
$ombra-caixeta-lateral-2x-x: 456px;
$ombra-caixeta-lateral-2x-y: 0px;
$ombra-caixeta-lateral-2x-offset-x: -456px;
$ombra-caixeta-lateral-2x-offset-y: 0px;
$ombra-caixeta-lateral-2x-width: 480px;
$ombra-caixeta-lateral-2x-height: 32px;
$ombra-caixeta-lateral-2x-total-width: 936px;
$ombra-caixeta-lateral-2x-total-height: 602px;
$ombra-caixeta-lateral-2x-image: 'img/spritesheet@2x.png?1625128582786';
$ombra-caixeta-lateral-2x: (456px, 0px, -456px, 0px, 480px, 32px, 936px, 602px, 'img/spritesheet@2x.png?1625128582786', 'ombra-caixeta-lateral@2x', );
$spritesheet-width: 468px;
$spritesheet-height: 301px;
$spritesheet-image: 'img/spritesheet.png?1625128582786';
$spritesheet-sprites: ($compartir-copiar-link, $compartir-correu, $compartir-facebook, $compartir-google, $compartir-link, $compartir-linkedin, $compartir-twitter, $fletxa-crowdfunding-anterior, $fletxa-crowdfunding-seguent, $ico-404, $ico-articles, $ico-arxiu-jpg, $ico-arxiu-keynote, $ico-arxiu-pdf, $ico-arxiu-png, $ico-arxiu-ppt, $ico-arxiu-txt, $ico-borsa, $ico-correu, $ico-creba, $ico-desplega, $ico-document-doc, $ico-documents, $ico-donacions-llegat, $ico-donacions-mevamanera, $ico-donacions-periodica, $ico-donacions-puntual, $ico-donacions-voluntariat, $ico-donate-boto-lg, $ico-donate-boto, $ico-email, $ico-events-home, $ico-groups, $ico-investigators, $ico-involucrat, $ico-microscopi-research, $ico-news-home, $ico-news, $ico-plataform-services, $ico-plega, $ico-publicacio-destacada, $ico-research, $ico-training-home, $ico-training, $ico-web, $icon-close, $icon-download, $icon-enllacos-pdf, $icon-enllacos, $icon-link, $icon-menu-small, $large-slider-endavant, $large-slider-enrera, $logo-linkedin, $logo-twitter, $lupa-cercador, $ombra-caixeta-lateral, );
$spritesheet: (468px, 301px, 'img/spritesheet.png?1625128582786', $spritesheet-sprites, );
$retina-spritesheet-width: 936px;
$retina-spritesheet-height: 602px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1625128582786';
$retina-spritesheet-sprites: ($compartir-copiar-link-2x, $compartir-correu-2x, $compartir-facebook-2x, $compartir-google-2x, $compartir-link-2x, $compartir-linkedin-2x, $compartir-twitter-2x, $fletxa-crowdfunding-anterior-2x, $fletxa-crowdfunding-seguent-2x, $ico-404-2x, $ico-articles-2x, $ico-arxiu-jpg-2x, $ico-arxiu-keynote-2x, $ico-arxiu-pdf-2x, $ico-arxiu-png-2x, $ico-arxiu-ppt-2x, $ico-arxiu-txt-2x, $ico-borsa-2x, $ico-correu-2x, $ico-creba-2x, $ico-desplega-2x, $ico-document-doc-2x, $ico-documents-2x, $ico-donacions-llegat-2x, $ico-donacions-mevamanera-2x, $ico-donacions-periodica-2x, $ico-donacions-puntual-2x, $ico-donacions-voluntariat-2x, $ico-donate-boto-lg-2x, $ico-donate-boto-2x, $ico-email-2x, $ico-events-home-2x, $ico-groups-2x, $ico-investigators-2x, $ico-involucrat-2x, $ico-microscopi-research-2x, $ico-news-home-2x, $ico-news-2x, $ico-plataform-services-2x, $ico-plega-2x, $ico-publicacio-destacada-2x, $ico-research-2x, $ico-training-home-2x, $ico-training-2x, $ico-web-2x, $icon-close-2x, $icon-download-2x, $icon-enllacos-pdf-2x, $icon-enllacos-2x, $icon-link-2x, $icon-menu-small-2x, $large-slider-endavant-2x, $large-slider-enrera-2x, $logo-linkedin-2x, $logo-twitter-2x, $lupa-cercador-2x, $ombra-caixeta-lateral-2x, );
$retina-spritesheet: (936px, 602px, 'img/spritesheet@2x.png?1625128582786', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$compartir-copiar-link-group-name: 'compartir-copiar-link';
$compartir-copiar-link-group: ('compartir-copiar-link', $compartir-copiar-link, $compartir-copiar-link-2x, );
$compartir-correu-group-name: 'compartir-correu';
$compartir-correu-group: ('compartir-correu', $compartir-correu, $compartir-correu-2x, );
$compartir-facebook-group-name: 'compartir-facebook';
$compartir-facebook-group: ('compartir-facebook', $compartir-facebook, $compartir-facebook-2x, );
$compartir-google-group-name: 'compartir-google';
$compartir-google-group: ('compartir-google', $compartir-google, $compartir-google-2x, );
$compartir-link-group-name: 'compartir-link';
$compartir-link-group: ('compartir-link', $compartir-link, $compartir-link-2x, );
$compartir-linkedin-group-name: 'compartir-linkedin';
$compartir-linkedin-group: ('compartir-linkedin', $compartir-linkedin, $compartir-linkedin-2x, );
$compartir-twitter-group-name: 'compartir-twitter';
$compartir-twitter-group: ('compartir-twitter', $compartir-twitter, $compartir-twitter-2x, );
$fletxa-crowdfunding-anterior-group-name: 'fletxa-crowdfunding-anterior';
$fletxa-crowdfunding-anterior-group: ('fletxa-crowdfunding-anterior', $fletxa-crowdfunding-anterior, $fletxa-crowdfunding-anterior-2x, );
$fletxa-crowdfunding-seguent-group-name: 'fletxa-crowdfunding-seguent';
$fletxa-crowdfunding-seguent-group: ('fletxa-crowdfunding-seguent', $fletxa-crowdfunding-seguent, $fletxa-crowdfunding-seguent-2x, );
$ico-404-group-name: 'ico-404';
$ico-404-group: ('ico-404', $ico-404, $ico-404-2x, );
$ico-articles-group-name: 'ico-articles';
$ico-articles-group: ('ico-articles', $ico-articles, $ico-articles-2x, );
$ico-arxiu-jpg-group-name: 'ico-arxiu-jpg';
$ico-arxiu-jpg-group: ('ico-arxiu-jpg', $ico-arxiu-jpg, $ico-arxiu-jpg-2x, );
$ico-arxiu-keynote-group-name: 'ico-arxiu-keynote';
$ico-arxiu-keynote-group: ('ico-arxiu-keynote', $ico-arxiu-keynote, $ico-arxiu-keynote-2x, );
$ico-arxiu-pdf-group-name: 'ico-arxiu-pdf';
$ico-arxiu-pdf-group: ('ico-arxiu-pdf', $ico-arxiu-pdf, $ico-arxiu-pdf-2x, );
$ico-arxiu-png-group-name: 'ico-arxiu-png';
$ico-arxiu-png-group: ('ico-arxiu-png', $ico-arxiu-png, $ico-arxiu-png-2x, );
$ico-arxiu-ppt-group-name: 'ico-arxiu-ppt';
$ico-arxiu-ppt-group: ('ico-arxiu-ppt', $ico-arxiu-ppt, $ico-arxiu-ppt-2x, );
$ico-arxiu-txt-group-name: 'ico-arxiu-txt';
$ico-arxiu-txt-group: ('ico-arxiu-txt', $ico-arxiu-txt, $ico-arxiu-txt-2x, );
$ico-borsa-group-name: 'ico-borsa';
$ico-borsa-group: ('ico-borsa', $ico-borsa, $ico-borsa-2x, );
$ico-correu-group-name: 'ico-correu';
$ico-correu-group: ('ico-correu', $ico-correu, $ico-correu-2x, );
$ico-creba-group-name: 'ico-creba';
$ico-creba-group: ('ico-creba', $ico-creba, $ico-creba-2x, );
$ico-desplega-group-name: 'ico-desplega';
$ico-desplega-group: ('ico-desplega', $ico-desplega, $ico-desplega-2x, );
$ico-document-doc-group-name: 'ico-document-doc';
$ico-document-doc-group: ('ico-document-doc', $ico-document-doc, $ico-document-doc-2x, );
$ico-documents-group-name: 'ico-documents';
$ico-documents-group: ('ico-documents', $ico-documents, $ico-documents-2x, );
$ico-donacions-llegat-group-name: 'ico-donacions-llegat';
$ico-donacions-llegat-group: ('ico-donacions-llegat', $ico-donacions-llegat, $ico-donacions-llegat-2x, );
$ico-donacions-mevamanera-group-name: 'ico-donacions-mevamanera';
$ico-donacions-mevamanera-group: ('ico-donacions-mevamanera', $ico-donacions-mevamanera, $ico-donacions-mevamanera-2x, );
$ico-donacions-periodica-group-name: 'ico-donacions-periodica';
$ico-donacions-periodica-group: ('ico-donacions-periodica', $ico-donacions-periodica, $ico-donacions-periodica-2x, );
$ico-donacions-puntual-group-name: 'ico-donacions-puntual';
$ico-donacions-puntual-group: ('ico-donacions-puntual', $ico-donacions-puntual, $ico-donacions-puntual-2x, );
$ico-donacions-voluntariat-group-name: 'ico-donacions-voluntariat';
$ico-donacions-voluntariat-group: ('ico-donacions-voluntariat', $ico-donacions-voluntariat, $ico-donacions-voluntariat-2x, );
$ico-donate-boto-lg-group-name: 'ico-donate-boto-lg';
$ico-donate-boto-lg-group: ('ico-donate-boto-lg', $ico-donate-boto-lg, $ico-donate-boto-lg-2x, );
$ico-donate-boto-group-name: 'ico-donate-boto';
$ico-donate-boto-group: ('ico-donate-boto', $ico-donate-boto, $ico-donate-boto-2x, );
$ico-email-group-name: 'ico-email';
$ico-email-group: ('ico-email', $ico-email, $ico-email-2x, );
$ico-events-home-group-name: 'ico-events-home';
$ico-events-home-group: ('ico-events-home', $ico-events-home, $ico-events-home-2x, );
$ico-groups-group-name: 'ico-groups';
$ico-groups-group: ('ico-groups', $ico-groups, $ico-groups-2x, );
$ico-investigators-group-name: 'ico-investigators';
$ico-investigators-group: ('ico-investigators', $ico-investigators, $ico-investigators-2x, );
$ico-involucrat-group-name: 'ico-involucrat';
$ico-involucrat-group: ('ico-involucrat', $ico-involucrat, $ico-involucrat-2x, );
$ico-microscopi-research-group-name: 'ico-microscopi-research';
$ico-microscopi-research-group: ('ico-microscopi-research', $ico-microscopi-research, $ico-microscopi-research-2x, );
$ico-news-home-group-name: 'ico-news-home';
$ico-news-home-group: ('ico-news-home', $ico-news-home, $ico-news-home-2x, );
$ico-news-group-name: 'ico-news';
$ico-news-group: ('ico-news', $ico-news, $ico-news-2x, );
$ico-plataform-services-group-name: 'ico-plataform-services';
$ico-plataform-services-group: ('ico-plataform-services', $ico-plataform-services, $ico-plataform-services-2x, );
$ico-plega-group-name: 'ico-plega';
$ico-plega-group: ('ico-plega', $ico-plega, $ico-plega-2x, );
$ico-publicacio-destacada-group-name: 'ico-publicacio-destacada';
$ico-publicacio-destacada-group: ('ico-publicacio-destacada', $ico-publicacio-destacada, $ico-publicacio-destacada-2x, );
$ico-research-group-name: 'ico-research';
$ico-research-group: ('ico-research', $ico-research, $ico-research-2x, );
$ico-training-home-group-name: 'ico-training-home';
$ico-training-home-group: ('ico-training-home', $ico-training-home, $ico-training-home-2x, );
$ico-training-group-name: 'ico-training';
$ico-training-group: ('ico-training', $ico-training, $ico-training-2x, );
$ico-web-group-name: 'ico-web';
$ico-web-group: ('ico-web', $ico-web, $ico-web-2x, );
$icon-close-group-name: 'icon-close';
$icon-close-group: ('icon-close', $icon-close, $icon-close-2x, );
$icon-download-group-name: 'icon-download';
$icon-download-group: ('icon-download', $icon-download, $icon-download-2x, );
$icon-enllacos-pdf-group-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-group: ('icon-enllacos-pdf', $icon-enllacos-pdf, $icon-enllacos-pdf-2x, );
$icon-enllacos-group-name: 'icon-enllacos';
$icon-enllacos-group: ('icon-enllacos', $icon-enllacos, $icon-enllacos-2x, );
$icon-link-group-name: 'icon-link';
$icon-link-group: ('icon-link', $icon-link, $icon-link-2x, );
$icon-menu-small-group-name: 'icon-menu-small';
$icon-menu-small-group: ('icon-menu-small', $icon-menu-small, $icon-menu-small-2x, );
$large-slider-endavant-group-name: 'large-slider-endavant';
$large-slider-endavant-group: ('large-slider-endavant', $large-slider-endavant, $large-slider-endavant-2x, );
$large-slider-enrera-group-name: 'large-slider-enrera';
$large-slider-enrera-group: ('large-slider-enrera', $large-slider-enrera, $large-slider-enrera-2x, );
$logo-linkedin-group-name: 'logo-linkedin';
$logo-linkedin-group: ('logo-linkedin', $logo-linkedin, $logo-linkedin-2x, );
$logo-twitter-group-name: 'logo-twitter';
$logo-twitter-group: ('logo-twitter', $logo-twitter, $logo-twitter-2x, );
$lupa-cercador-group-name: 'lupa-cercador';
$lupa-cercador-group: ('lupa-cercador', $lupa-cercador, $lupa-cercador-2x, );
$ombra-caixeta-lateral-group-name: 'ombra-caixeta-lateral';
$ombra-caixeta-lateral-group: ('ombra-caixeta-lateral', $ombra-caixeta-lateral, $ombra-caixeta-lateral-2x, );
$retina-groups: ($compartir-copiar-link-group, $compartir-correu-group, $compartir-facebook-group, $compartir-google-group, $compartir-link-group, $compartir-linkedin-group, $compartir-twitter-group, $fletxa-crowdfunding-anterior-group, $fletxa-crowdfunding-seguent-group, $ico-404-group, $ico-articles-group, $ico-arxiu-jpg-group, $ico-arxiu-keynote-group, $ico-arxiu-pdf-group, $ico-arxiu-png-group, $ico-arxiu-ppt-group, $ico-arxiu-txt-group, $ico-borsa-group, $ico-correu-group, $ico-creba-group, $ico-desplega-group, $ico-document-doc-group, $ico-documents-group, $ico-donacions-llegat-group, $ico-donacions-mevamanera-group, $ico-donacions-periodica-group, $ico-donacions-puntual-group, $ico-donacions-voluntariat-group, $ico-donate-boto-lg-group, $ico-donate-boto-group, $ico-email-group, $ico-events-home-group, $ico-groups-group, $ico-investigators-group, $ico-involucrat-group, $ico-microscopi-research-group, $ico-news-home-group, $ico-news-group, $ico-plataform-services-group, $ico-plega-group, $ico-publicacio-destacada-group, $ico-research-group, $ico-training-home-group, $ico-training-group, $ico-web-group, $icon-close-group, $icon-download-group, $icon-enllacos-pdf-group, $icon-enllacos-group, $icon-link-group, $icon-menu-small-group, $large-slider-endavant-group, $large-slider-enrera-group, $logo-linkedin-group, $logo-twitter-group, $lupa-cercador-group, $ombra-caixeta-lateral-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
