// membre destacat
.member {
  text-align: center;
  @media #{$medium-up} {
    text-align: left;
    padding-bottom: 0;
  }
  &__img {
    margin-bottom: vr(1);
    @media #{$medium-up} {
      margin-bottom: 0;
    }
  }
  &__wrapper {
    @media #{$medium-up} {
      margin-top: vr(1);
      .medium-3 & {
        border-right: 1px solid #fff;
      }
    }
    @media #{$large-up} {
      margin-top: vr(2);
    }
    @media #{$xlarge-up} {
      margin-top: vr(3);
    }
  }
  &__name {
    @include regular;
    @include typi(t3);
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: vr(.5);
    @media #{$medium-up} {
      text-align: left !important;
    }
  }
  p {
    @include typi(s1);
    margin: 0;
    &.member__descr-1 {
      @include italic;
      @include typi(s2);
      text-transform: uppercase;
      margin-bottom: 0;
    }
    &.member__descr-2 {
      @include italic;
      @include typi(s2);
      margin-bottom: vr(.75);
    }
    &.member__id {
      @include bold;
      margin-bottom: vr(.5);
      a {
        @include regular;
        display: block;
      }
    }
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}


// fitxa membre
.js-border {
  @media #{$medium-up} {
    border-right: 1px solid #d9d9d9;
  }
}
.member-view {
  margin-bottom: vr(2);
  text-align: center;
  &__img {
    border-radius: 100px;
  }
  &__name {
    margin-top: 0;
    span {
      @include bold;
    }
  }
  p {
    @include typi(s1);
    margin-bottom: vr(.5);
  }
}