.cursos-list {
  @media only screen and (min-width: em-calc(600)) {
    @include block-grid(2);
  }
}
.curs {
  color: #fff;
  background-color: #3a4e23;
  padding: vr(1);
  &.curs--closed {
    color: #6b6b6b;
    background-color: #e3e3e3;
  }
  &__title {
    @include light;
    @include typi(curs);
    text-transform: none;
    text-align: left;
    letter-spacing: 0;
    margin-bottom: vr(.75);
    .curs--closed & {
      color: inherit;
    }
    a {
      color: #fff;
      border: 0;
      .curs--closed & {
        color: inherit;
      }
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  &__info {
    @include bold;
    text-transform: uppercase;
    color: #dfe9a0;
    margin-bottom: vr(.5);
    .curs--closed & {
      color: #d0021b;
    }
  }
  &__dates {
    @include cf;
    margin-bottom: 0;
    dt {
      @include bold;
    }
    dd {
      margin-left: 0;
    }
  }
  &__termini {
    margin-bottom: vr(.5);
    dt,
    dd {
      color: #dfe9a0;
      .curs--closed & {
        color: inherit;
      }
    }
  }
  &__data {
    width: 50%;
    float: left;
    padding-right: vr(.5);
    dt {
      @include typi(s1);
    }
    dd {
      @include light;
      @include typi(t3);
    }
  }
}
