.pagination-centered {
    text-align: center;
}
ul.pagination {
  padding-top: 2rem;
  margin-left: 0px;
  margin-bottom: rem-calc(40);
  li {
    display: inline-block;
    &:hover {
      a {
        color: $white;
      }
    }
    &:before {
      content: none;
    }
    &.hide {
      display: none;
    }

    &:last-child {
      a {
        border-right: 0;
      }
    }

    a {
      border-radius: 0;
      transition: none;
      background: #f4f4f4;
      color: $primary-color !important;
      border-bottom: none;
      &:hover {
        color: $white !important;
        border-bottom: 0;
        text-decoration: none;
        background-color: $primary-color;
      }
    }
    &.current {
      a {
        color: $white !important;
        background-color: $primary-color;
      }
    }
    &.unavailable {
      a {
        background: none;
        opacity: .5;
      }
    }

    &.arrow {
      a {
        padding-top: 0;
        padding-bottom: 0;
        background: none;
        text-transform: uppercase;
        color: $primary-color !important;
      }

      img {
        width: 29px;
      }

      span {
        display: none;
      }

      &.prev {
        a {
          padding-left: 0;
        }
      }

      &.next {
        a {
          padding-right: 15px;
        }
      }
    }
  }

  @media #{$small-up} {

    li {
      display: none;
      a {
        font-size: rem-calc(15)!important;
        padding: 0.5rem 0.75rem;
      }

      &.hide {
        display: inline-block;
      }

      &.arrow {
        margin-top: 3px;

        a {
          border-right: 0;
        }

        img {
          width: 40px;
        }

        &.prev {
          float: left;
          display: block;
        }

        &.next {
          float: right;
          display: block;
        }
      }
    }
  }

  @media #{$medium-up} {
    li {
      display: inline-block;
      &.arrow {
        span {
          display: inline;
        }

        a:link {
          &:hover {
            text-decoration: underline;
            color: $body-font-color;
          }
        }
      }

      &.prev {
        img {
          margin-right: 10px;
        }
      }

      &.next {
        img {
          margin-left: 10px;
        }
      }
    }
  }
}
