.research-list {
  margin: 0;
  @media #{$medium-up} {
    margin-bottom: vr(2);
  }
  li {
    @include bold;
    padding-left: 0;
    margin-bottom: vr(1);
    &:before {
      display: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      @include typi(s1);
      @include italic;
      display: block;
      margin-top: vr(.25);
    }
  }
  a {
    border-bottom: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}
.resarch-group {
  background-color: #f2f2f2;
   //min-height: rem-calc(500);
  .news-item__img {
    margin-bottom: 0 !important;
  }
  .box-group {
    padding: rem-calc(15);
   
    .resp {
      //border: 1px solid #ba5382;
      //border-radius: 5px;
      //padding: .375rem;
      margin-bottom: rem-calc(10);
      span.resp-title {
        font-size: rem-calc(14);
        color: #ba5382;
      }
      ul {
      margin-left: 0;
      font-size: rem-calc(12);
      font-style: italic;
      padding-bottom: 0;
      margin-bottom: 0;
      li {
        padding-left: 0;
        margin-bottom: 0;
        &:before {
          content: none;
        }
      }
    }
    }
    
  }
}
.research-intro {
  text-align: center;
  margin-bottom: rem-calc(50);
}
.info-box-research {
  padding-bottom: rem-calc(30);
  a {
    img {
      margin-bottom: rem-calc(10);
      transition: all .2s ease-in-out;
    }
    h2 {
      margin-bottom: rem-calc(5);
    }
    &:hover {
      h2 {
        text-decoration: underline;
      }
      img {
        transform: scale(1.1);
      }
    }
  }
  p {
    font-size: rem-calc(14) !important;
    margin-bottom: rem-calc(5) !important;
  }
  .more {
    margin-top: rem-calc(15) !important;
  }
}
.research-intro {
  position: relative;
  .button--back {
    position: absolute;
    top: 0;
    right: -200px;
  }
}
