.main-footer {
  background-color: #f7f7f7;
  //margin-top: vr(2);
  .home & {
      background-color: #fff;
    }
  &__top {
    text-align: center;
    background-color: #fff;
     .home & {
      background-color: #f7f7f7;
    }
  }
  @media only screen and (max-width: 500px) {
    ul.logos{
      li{
        margin-right:6px;
        &.first{
          margin-left:0;
        }
        &.last{
          margin-right:0;
        }
        img{
          width: 60px;
          &.hr-excellence-in-research,&.creba-centre-de-recerca-biomedia-avancada{
            width:85px;
          }
        }

      }
    }
  }
  &__bottom {
    @include typi(s1);
    //padding-top: vr(2);
    text-align: center;
    background-color: #f7f7f7;
    .home & {
      background-color: #fff;
    }
    @media #{$medium-up} {
      text-align: left;
      .medium-8 {
        border-left: 1px solid #dfdfdf;
      }
    }
    p {
      margin-bottom: 0;
    }
    a {
      color: $primary-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    ul {
      margin-top: rem-calc(15);
      @include typi(s1);
      list-style: none;
      margin-left: 0;
      span {text-transform: none;}

      li {
        margin-bottom: vr(.5);

        @media #{$medium-up} {
          display: inline-block;
          text-transform: uppercase;
          &:after {
            content: '\00B7';
            margin: 0 .5em;
          }
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

  }
  &__logo {
      width: 105px;
      margin-bottom: vr(0.5);
      margin-left:auto;
      margin-right:auto;
      display:block;  

    }
}
#cercador-box {
  z-index:11;
  position:absolute;
  margin:1px 0;
  padding:4.375em 0;
  width:100%;
  height:100%;
  background:$primary-color;
  background:rgba($primary-color,0.9);
  right:0;
  top:0;
  display:none;
  img {
    padding-bottom:15px;
    float: none;
    display: block;
    margin: 0 auto;
  }
  form {
    padding:30px 75px 50px;
    max-width:800px;
    margin:0 auto;
  }
  .cercar {
    border:0 none;
    color:#999;
    font-size:25px;
    outline:none;
    padding:10px;
    background: #fff;
    width: 100%;
  }
  .button {
    font-size:1.4375em;
    background-color:transparent;
    border:solid 2px #fff !important;
    //padding:.5em 2em;
    font-size: rem-calc(18);
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    color: #fff;
    margin-top: rem-calc(20);
      &:hover {
        background-color: $primary-color;
      }
  }
}
  .icon-close {
      display: block;
  }
  .boto-tancar {
      color:#fff;
      background-color:rgba(255,255,255,0.2);
      display:block;
      font-size:2em;
      padding:20px 20px 15px;
      position:fixed;
      right:20px;
      top:20px;
      &:hover {
        color:#F26F1C;
        transition:all 0.4s ease 0s;
      }
  }
  .cerca-element {
      background-color:#F0F1F1;
      border-left:4px solid #F26F1C;
      padding:18px 24px 10px;
      margin-bottom:1em;
      h3 {
        background:none;
        padding:0;
        margin-bottom:.25em;
      }
  }
