// mixins

// Triangle
// --------
// We use this to create isosceles triangles
// - $triangle-size       - Used to set border-size. No default, set a px or em size.
// - $triangle-color      - Used to set border-color which makes up triangle. No default
// - $triangle-direction  - Used to determine which direction triangle points. 
//                          Options: top, bottom, left, right
@mixin triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if $triangle-direction == top {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if $triangle-direction == bottom {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if $triangle-direction == left {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if $triangle-direction == right {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// visually hidden
@mixin visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;  
} 

// underline on hover
@mixin hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// treure klanders de llista
@mixin undolist {
  margin-left: 0;
  li {
    padding-left: 0;
    position: static;
    margin-bottom: 0;
    &:before {
      display: none;;
    }
  }
}

// fonts
@mixin black {
  font-family: lato_black, $font-family-sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin bold {
  font-family: lato_bold, $font-family-sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin italic {
  font-family: lato_italic, $font-family-sans-serif;
  font-weight: 400;
  font-style: italic;
}
@mixin regular {
  font-family: lato_regular, $font-family-sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin lightitalic {
  font-family: lato_lightitalic, $font-family-sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin light {
  font-family: lato_light, $font-family-sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin thin {
  font-family: lato_hairlineregular, $font-family-sans-serif;
  font-weight: 100;
  font-style: normal;
}

// Clearfix
// --------
@mixin cf {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}