.news-dest {
  @media #{$small-only} {
    padding-left: $column-gutter/2;
    padding-right: $column-gutter/2;
  }
 // @media #{$small-up} {
    margin-bottom: vr(1.5);
    &__text {
      padding-right: $column-gutter/2 !important;
    }
    &__container {
      background-color: #f2f2f2;
      padding-left: vr(0.85) !important;
      padding-right: $column-gutter/2 !important;
      padding-top: vr(0.85);
      padding-bottom: vr(0.85);
    }
    &__img {
      padding-left: $column-gutter/2 !important;
      img {
        margin-bottom: 0;
      }
    }
    p {
      margin-bottom: 0;
    }
 // }
  &__title {
    @include light;
    @include typi(curs);
    text-align: left;
    text-transform: none;
    letter-spacing: 0;
    margin-top: 0;
    a {
      color: $body-font-color;
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
  p {
    @include bold;
    @include typi(s1);
  }


}

.news-item {
  margin-bottom: vr(1.5);
  .home & {
    margin-bottom: vr(1);
  }
  &--0{
    .home & {
      margin-bottom: vr(1.5);
    }
  }

  .last & {
    margin-bottom: 0;
  }
  .time{
    font-size:rem-calc(14);
    color: #ba5382;
    display: flex;
    align-items: baseline;
  }
  &__title {
    text-align: left;
    text-transform: none;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: vr(.25);
    a {
      color: $body-font-color;
      border: 0;
      &:hover {
        border-bottom: 1px solid;
      }
    }
    &.conv{
        font-size:rem-calc(14);
        line-height: 1.2;
        min-height: rem-calc(50);
    }
  }
  &__img {
    text-align: left;
    margin-bottom: vr(.5);
  }
  p {
    @include typi(s1);
    margin-bottom: 0;
  }
  &--smaller {
    .news-item__title {
      @include typi(s1);
    }
  }
}
.meta {
    width: 100%;
    text-align: center;
    margin-top: rem-calc(50);
}

.column+.column:last-child, .column+.columns:last-child, .columns+.column:last-child, .columns+.columns:last-child {
  float: left;
}
.dataAgenda {
  color: $primary-color;
  font-size: rem-calc(14);
  text-align: center;
  margin-top: rem-calc(50);
  margin-bottom: 0;
  time {
    display: inline-block;
    font-size: rem-calc(12);
  }
}
.teaching{
  h1{
    margin-top:1.5rem;
  }
}
.news,.teaching{
  h1{
    max-width: 700px;
    margin-left:auto;
    margin-right:auto;
     margin-top:1rem;
  }
}
