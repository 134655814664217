.small-nav {
  @include typi(s2);
  margin: 0 auto vr(1);
  text-align: center;
  text-transform: uppercase;
  color: $primary-color;
  @media #{$medium-up} {
    margin-bottom: vr(1.5);
  }
  li {
    padding-left: 0;
    margin: 0;
    display: inline-block;
    &:before {
      display: none;
    }
    &:after {
      content: '\2758';
      display: inline-block;
      margin: 0 .5em;
    }
    &:last-child:after {
      display: none;
    }
    &.current {
      a {
        @include bold;
        color: $body-font-color;
      }
    }
  }
  a {
    border-bottom: 0;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}