.breadcrumb {
  @include typi(breadcrumb);
  display: block;
  margin: vr(1) 0;
  text-align: center;
  @include bold;
  color: #6c1d41;
  @media #{$medium-up} {
    @include regular;
    margin-top: vr(2);
  }
  .hide-for-small {
    text-transform: uppercase;
    @include regular;
    @media #{$medium-up} {
      @include light;
      display: inline !important;
    }
  }
  a {
    color: #6c1d41;
    border: 0;  
    &:hover {
      border-bottom: 1px solid;
    }
  }
}