.info-block {
	margin-bottom: vr(2);
	.more{
		margin-top: vr(1.5);
	}
	&__title {
		@include typi(home-title);
		@include thin;
		text-transform: uppercase;
		letter-spacing: 0;
		margin-bottom: vr(1);
		margin-left: -6px;
		&:before {
			content: '';
			display: block;
			margin-bottom: 10px;
			margin-left: 6px;
		}
	}
	&--news {
		h3{
			margin-bottom: rem-calc(13);
		}
		@media #{$medium-up} {
			padding-right: rem-calc(16);
		}
		@media #{$large-up} {
			padding-right: rem-calc(30);
		}
		.info-block__title {
			&:before {
				@extend .ico-news-home;
			}
		}
		time {
			@include typi(s2);
			margin-bottom:rem-calc(5);

		}

	}
	&--events {
		@media #{$medium-up} {
			padding-left: rem-calc(8);
			padding-left: rem-calc(8);
		}
		@media #{$large-up} {
			padding-left: rem-calc(15);
			padding-left: rem-calc(15);
		}
		.info-block__title {
			&:before {
				@extend .ico-events-home;
			}
		}
	}
	&--training {
		@media #{$medium-up} {
			padding-left: rem-calc(16);
		}
		@media #{$large-up} {
			padding-left: rem-calc(30);
		}
		.info-block__title {
			&:before {
				@extend .ico-training-home;
			}
		}
	}
}
.events-block {
	margin-bottom: vr(1.5);
	&__time {
		background-color: #80b5e2;
		color: #fff;
		padding: vr(.25);
		border-radius: 5px;
		text-transform: uppercase;
		@include typi(s2);
		span {
			@include typi(t1);
			@include light;
			display: block;
		}
	}
	&__title {
		@include light;
		margin-bottom: vr(.25);
		a {
			border: 0;
			color: inherit;
			&:hover {
				border-bottom: 1px solid;
			}
		}
	}
	p {
		@include typi(s1);
		margin-bottom: 0;
	}
}
.training-block {
	margin-bottom: vr(1.5);
	&:nth-child(4) {
		margin-bottom: 0;
	}
	&__title {
		@include light;
		margin-bottom: vr(.5);
		a {
			border: 0;
			color: inherit;
			&:hover {
				border-bottom: 1px solid;
			}
		}
	}
	&__category {
		@include light;
		margin-bottom: vr(.5);
		font-size: rem-calc(11);

			border: 0;
			color: inherit;
			text-decoration: none;
			&:hover {
				border-bottom: 1px solid;
			}

	}
	&__time {
		border: 1px solid $primary-color;
		border-radius: 5px;
		padding: vr(.25);
		p {
			@include typi(s2);
			@include bold;
			margin-bottom: 0;
			time {
				@include regular;
				@include typi(s2);
			}
		}
	}
}
.home .jobs {
	a.button{
		margin-right: vr(.5);
	}
}