.button {
  @include typi(btn);
  @include bold;
  display: inline-block;
  margin-bottom: vr(.75);
  text-decoration: none;
  padding: 0 vr(1);
  transition: background-color .1s;
  text-transform: uppercase;
  text-align: center;
  background-color: #fff;
  color: $primary-color;
  border: 1px solid $primary-color;
  &:hover,
  &:focus {
    background-color: $primary-color;
    color: #fff;
    border: 1px solid $primary-color;
    text-decoration: none !important;
  }

  &--secondary {
    border: 1px solid $primary-color !important;
    background-color: transparent;
    .card & {
      width: 100%;
    }
  }

  &--tertiary {
    border: 1px solid #fff !important;
    background-color: #fff;
    &:hover,
    &:focus {
      border-color: $primary-color !important;
    }
  }

  &--rounded {
    border-radius: 5px;
  }

  &--small {
    line-height: 35px;
    padding: 0 vr(.75);
  }

  &--search {
    background-color: transparent;
    line-height: 35px;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: sub;
      margin-right: 5px;
      @extend .lupa-cercador;
    }
  }
  &--proj {
    clear: left;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: rem-calc(200);
  }
}

.donate-btn {
  @include typi(donate);
  color: #fff;
  background-color: #a8b70f;
  padding: rem-calc(9 10 9 50);
  margin-bottom: vr(1);
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;  
  border: 0;
  text-decoration: none;
  position: relative;
  transition: background-color .2s; 
  float: right;
  @media #{$medium-up} {
    padding-right: rem-calc(20);
  }
  &:before {
    content: '';
    @extend .ico-donate-boto-lg;
    display: block;
    position: absolute;
    left: 8px;
    bottom: 1px;
  }
  &:hover {
    background-color: darken(#a8b70f,5%);
  }

}
