.logos {
  list-style: none;
  text-align: center;
  margin: 0;
  .main-footer & {
    margin-bottom: vr(2);
  }
  &.crowdfunding {
    padding: vr(1) 0;
    @media #{$large-up} {
      margin-top: vr(2);
      padding:0;
    }
  }
  .home & {
    &.crowdfunding {
      padding: 0;
    }
  }
  li {
    margin: 0 auto vr(1);
    padding-left: 0;
    &:before {
      display: none;
    }

      display: inline-block;
      vertical-align: middle;
      margin-left: vr(1);
      margin-right: vr(1);

  }
  a {
  	border: 0;
  }
}
.logos-bloc{
    border-bottom: solid 6px #d9d9d9;
    border-top: solid 4px #ba5382;;
    padding-top: 2rem;
    margin-bottom: 3rem;
    background-color:#fff;

ul{
    margin-left: 1.25rem;
}
  li {
    margin: 0 auto vr(1);
    padding-left: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &:before {
      display: none;
    }
    &.first{
      margin-left:0;
    }
    &.last{
      margin-right:0;
    }

  }
  a {
  	border: 0;
    span{
      display:block;
      font-size: 10px;
      color:#000;
    }
  }
  .row{
    &.last{
      text-align: center;
      margin-top:20px;
      margin-bottom:20px;
      max-width: 44rem;
      @media #{$large-up} {
        text-align: left;
      }
      ul{
        margin:0 0 30px 0;
      }
      li{
       // margin-bottom:0;
      }
      h2{
        margin-bottom: 1rem;
      }
      @media #{$large-up} {
        .border {
          &:before{
          content: ' ';
          border-left: 1px solid #d9d9d9;
          margin-left: -40px;
          min-height: 150px;
          float: left;
          }
        }

      }
    }
  }
}
