.share {
	//display: none;

		display: block;
		text-align: center;
		margin-left: 0;
		margin-bottom: vr(2);
		&__list {
			list-style: none;
			margin: 0;
		}
		li {
			padding-left: 0;
			display: inline-block;
			margin: 0 vr(.5);
			vertical-align: middle;
			&:before {
			  display: none;
			}
		}
		a {
			display: inline-block;
			opacity: 1;
			transition: opacity .1s;
			border: 0;
			&:hover {
				opacity: .8;
			}
		}
		&--copylink {
			@include sprite($compartir-copiar-link);
			@include retina-sprite($compartir-copiar-link-group);
		}
		&--link {
			@include sprite($compartir-link);
			@include retina-sprite($compartir-link-group);
		}
		&--e {
			@include sprite($compartir-correu);
			@include retina-sprite($compartir-correu-group);
		}
		&--tw {
			@include sprite($compartir-twitter);
			@include retina-sprite($compartir-twitter-group);
		}
		&--fb {
			@include sprite($compartir-facebook);
			@include retina-sprite($compartir-facebook-group);
		}
		&--li {
			@include sprite($compartir-linkedin);
			@include retina-sprite($compartir-linkedin-group);
		}
		&--g {
			@include sprite($compartir-google);
			@include retina-sprite($compartir-google-group);
		}

}
