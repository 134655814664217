.main-content {
  &--bg {
    background-color: gray;
    background-size: cover;
    padding-bottom: vr(2);
    .error404 & {
      background-image: url('img/foto-404.jpg');
      color: #fff;
    }
  }
}