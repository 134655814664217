// body {
//   overflow-x: hidden; // per evitar scroll horitzonal en alguns navegadors quan s'utlitza la classe .u-wider en algun element.
// }

// imatge top
.top-img {
  margin-bottom: vr(2);
}

// text informatiu
.info {
  @include typi(s2);
  color: #4c4c4c;
}

// títol pàgina
.page-title {
  display: block;
  text-align: center;
  text-transform: uppercase;
  @include typi(t3);
  @include light;
  text-align: center;
  margin: vr(2) 0 vr(1);
  color: #6c1d41;
}

// figure
figure {
  text-align: center;
}
figcaption {
  @include typi(s2);
  text-align: left;
  padding: vr(.5) 0 0;
  @media #{$medium-up} {
    width: 50%;
  }
  &.full {
    width: 100%;
  }
}

// paràgraf destacat
.dest {
  @include typi(t3);
  @include light;
  text-align: center;
  padding: vr(1) 0;
  margin: vr(2) 0;
  position: relative;
  &:before,
  &:after {
    display: block;
    content: '';
    width: rem-calc(200);
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-100);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  @media #{$medium-up} {
    padding: vr(1) vr(2);
    &:before,
    &:after {
      width: rem-calc(380);
      left: 50%;
      margin-left: rem-calc(-190);
    }
  }
}

.destacat {
  @include typi(t3);
  @include light;
  text-align: left;
  padding: vr(1) 0;
  margin: vr(2) 0;
  position: relative;
  list-style: none;
  &:before,
  &:after {
    display: block;
    content: '';
    width: rem-calc(200);
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-100);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  li {
    font-size: 16px;
    line-height: 1.13;
    color: #4a4a4a;
    font-weight: 500;
    &:before {
      content: none;
    }
  }
  @media #{$medium-up} {
    //padding: vr(1) vr(2);
    &:before,
    &:after {
      width: rem-calc(440);
      left: 50%;
      margin-left: rem-calc(-210);
    }
  }
}
.destacat_news {
  @include typi(t3);
  @include light;
  text-align: left;
  padding: vr(1) 0;
  margin: vr(2) 0;
  position: relative;
  list-style: none;
  &:before,
  &:after {
    display: block;
    content: '';
    width: rem-calc(200);
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-100);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  li {
    font-size: 16px;
    line-height: 1.13;
    color: #4a4a4a;
    font-weight: 500;
    &:before {
      content: none;
    }
  }
  @media #{$medium-up} {
    //padding: vr(1) vr(2);
    &:before,
    &:after {
      width: rem-calc(420);
      left: 50%;
      margin-left: rem-calc(-210);
    }
  }
}
ul.destacat {
  @include typi(t3);
  @include light;
  text-align: center;
  padding: vr(1) 0;
  margin: vr(2) 0;
  position: relative;
  list-style: none;
  &:before,
  &:after {
    display: block;
    content: '';
    width: rem-calc(200);
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-100);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  li {
    font-size: 16px;
    line-height: 1.13;
    color: #4a4a4a;
    font-weight: 500;
    &:before {
      content: none;
    }
  }
  @media #{$medium-up} {
    //padding: vr(1) vr(2);
    &:before,
    &:after {
      width: rem-calc(440);
      left: 50%;
      margin-left: rem-calc(-210);
    }
  }
}
/*.dest-2 {
  @include typi(t3);
  @include light;
  @media #{$medium-up} {
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #6c1d41;
      position: absolute;
      right: vr(-2);
      top: 0;
    }
  }
}*/
.dest-2 {
  p {
    @include typi(t3);
    @include light;
    @media #{$medium-up} {
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: #6c1d41;
        position: absolute;
        right: vr(-2);
        top: 0;
      }
    }
  }
}

// baixar arxiu destacat
.dl {
  @include typi(s1);
  background-color: #e9e9e9;
  padding: vr(.25) vr(.5);
  position: relative;
  &:before {
    content: '';
    // @extend .baixar-destacat;
    display: block;
    position: absolute;
    left: -21px;
    top: 50%;
    margin-top: -8px;
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  border-left: 28px solid $primary-color;
}

// resultat cerca
.result {
  text-transform: none;
  background-color: lighten($primary-color,60%);
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 0 vr(.75);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
div#gmap {
    width: 100%;
    height: 400px;
    text-align: center;
    font-size: .75em;
    line-height: 120%;
    padding: 0;
    overflow: hidden;
}
.data_convoc {
  time {
    display: inline-block;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*---------tables-------------*/
table {
  border: none;
  tr {
    border-bottom: 1px solid #d9d9d9;
    td:first-child {
      font-weight: 900;
    }
    td {
      color: #4a4a4a;
    }
  }
}
.teaching {
  main {
    .info-box {
      p {
        a {
          color: #fff;
          border-bottom: .0625rem solid #fff;
        }
      }
    }
  }
}
.bott {
  border-bottom: 1px solid #d9d9d9;
}
.link_crowd{
  margin-bottom: rem-calc(50);
}

.funding-slider {
  a {
    border-bottom: none;
  }
}
.advant {
  margin-top: rem-calc(25);
}
.txtImgEsq, .img-left {
  img {
    float: left;
    padding-right: rem-calc(20);
  }
}
.txtImgDr, .img-right {
  img {
    float: right;
    padding-left: rem-calc(20);
  }
}
.txtPetit{
    font-size: 14px;
}

.teaching {
  .type1 {
    font-size: rem-calc(18);
    margin-bottom: rem-calc(5);
  }
  .cat_title {
    font-size: rem-calc(30);
    text-align: center;
    margin-top: rem-calc(10);
  }
}
.crowd-jobs{
  overflow: hidden;
 /*margin-bottom: 2rem;*/
  /*border-bottom: solid 4px $primary-color;  */
}
table.dataTable.no-footer {
    border-bottom: 0 !important;
}
table.dataTable thead th, table.dataTable thead td {
    border-bottom: 1px solid #d9d9d9 !important;
}
table thead {
    background: #fff;
}
table select {
    background-color: #fff;
    @include typi(s1);
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    padding: vr(.25) 0;
    color: #4c4c4c;
    width: 100%;
}


.trobats{
  color: #ba5382 !important;
  font-size: 1.2em;
}
