.estat-conv{
    margin-bottom: 1rem !important;
    &::before {
        content: ' ';
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 1000px;
        vertical-align: text-bottom;
        margin-right: 8px;
        border:1px solid;
      }
      &.estat0::before {
        background-color: #D0021B;
      }
      &.estat1::before {
        background-color: #76AC36;
      }

}
