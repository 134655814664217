.collapsible {

  &__title {
    // @include typi(s1);
    // @include medium;
    // margin: 0;
    [aria-expanded] {
      display: block;
      width: 100%;
      text-align: left;
      margin: 0;
      padding: 0;
      border: 0;
      background-color: transparent;
      text-transform: uppercase;
    }
    // tancat
    [aria-expanded="false"] {
      // margin: 0 0 vr(.5);
    }
    // obert
    [aria-expanded="true"] {
      // background-color: #f4f4f4;
      // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    }
    + [aria-hidden] {
      display: none;
    }
    + [aria-hidden="false"] {
      display: block;
      margin: 0 0 vr(1);
      padding: 0 0 vr(1);
    }
  }

  &__body {
    // @include typi(s1);
    // padding: vr(1);
    // background-color: #fff;
    // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid #d9d9d9;
    // *:last-child {
    //   margin-bottom: 0;
    // }
  }
}
