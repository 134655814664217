.crowdfunding{
    padding-top:rem-calc(30);
    padding: 1.2rem 2rem;
    
    h2{
        margin-bottom:rem-calc(70);
    }
    .link_crowd {
        margin-bottom: 0;
        margin-top: 1.8rem;
    }
}