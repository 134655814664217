// slider principal home
.home-slider {
	text-align: center;
	overflow: hidden;
	min-height: rem-calc(240);
	@media #{$medium-up} {
		min-height: rem-calc(340);
	}
	@media #{$large-up} {
		min-height: rem-calc(400);
	}
	.slick-slide {
		position: relative;
		height: rem-calc(300);
		background-color: gray;
		background-size: cover;
		background-position: 50% 0;
		@media #{$medium-up} {
			height: rem-calc(340);
		}
		@media #{$large-up} {
			height: rem-calc(400);
		}
		div{
			height: 100%;
			&.home-slider__caption{
				height: auto;
			}
		}
		.overlay{
			height: 100%;
			width: 100%;
			position: absolute;
			background-color: rgba(#000,.5);
    		z-index: 1;
		}
	}
	.slick-prev,
	.slick-next {
		&:before {
			content: '';
		}
		&:hover,
		&:focus {
			opacity: .8;
		}
	}
	.slick-prev {
		left: vr(.5);
		z-index: 1;
		@extend .large-slider-enrera;
		&:hover,
		&:focus {
			@extend .large-slider-enrera;
		}
	}
	.slick-next {
		right: vr(.5);
		@extend .large-slider-endavant;
		&:hover,
		&:focus {
			@extend .large-slider-endavant;
		}
	}
	&__box{
		background-color: rgba(#000,.5);

		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 0 vr(2);
		width: 100%;
		@media #{$medium-up} {
			text-align: left;
			width: 50%;
			left: 0;
		}
		@media #{$large-up} {
			width: 40%;
		}
		div{
			position: absolute;
			top:50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: auto!important;
			width:100%;
			padding: vr(1) vr(2.5);
		}
		p {
			@include typi(caption);
			@include regular;
			color: #fff;
			text-shadow: 0 2px 3px rgba(0,0,0,.4);
		}
		a.button {
			font-size: rem-calc(14);
			padding:.5em 1em;
			line-height: 18px;
			background-color: transparent;
			color:#fff;
			border-color: #fff;
			text-transform: none;
			&:hover{
				background-color: #fff;
				color: $primary-color;
			}
		}
	}
	&__caption {
		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 0 vr(2);


		@media #{$medium-up} {
			width: 60%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		p {
			@include typi(caption);
			@include regular;
			color: #fff;
			text-shadow: 0 2px 3px rgba(0,0,0,.4);


		}
		a.button {
			font-size: rem-calc(14);
			padding:.5em 1em;
			line-height: 18px;
			background-color: transparent;
			color:#fff;
			border-color: #fff;
			text-transform: none;
			&:hover{
				background-color: #fff;
				color: $primary-color;
			}
		}

	}
	.slick-dots {
		bottom: 0;
		height: vr(1.5);
		background-color: #fff;
		background-color: rgba(255,255,255,.5);
		li {
			top: 2px;
			&:before {
				display: none;
			}
		}
	}
}

// slider crowdfunding
.funding-slider {
	text-align: center;
	overflow: hidden;
	padding-left: vr(2);
	padding-right: vr(2);
	margin-bottom: vr(1);
	display: none;
	a {color: #000;}
	&.slick-initialized{
		display: block;
	}
	.slick-slide {
		text-align: center;
		vertical-align: middle;
		float: none;
		margin-left:8px;
		margin-right:8px;
		display: inline-block;
		img {
			display: inline;
		}
	}
	.slick-prev,
	.slick-next {
		color:#000;
		&:before {
			content: '';
		}
		&:hover,
		&:focus {
			opacity: .8;
		}
	}
	.slick-prev {
		left: 0;
		z-index: 1;
		@extend .fletxa-crowdfunding-anterior;
		&:hover,
		&:focus {
			@extend .fletxa-crowdfunding-anterior;
		}
	}
	.slick-next {
		right: 0;
		@extend .fletxa-crowdfunding-seguent;
		&:hover,
		&:focus {
			@extend .fletxa-crowdfunding-seguent;
		}
	}
}