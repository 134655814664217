.dl-data {
  text-align: center;
  @media only screen and (min-width: em-calc(500)) {
    @include cf;
  }
  div {
    margin-bottom: vr(.5);
    @media only screen and (min-width: em-calc(500)) {
      /*
      width: 33.333%;
      float: left;
      */
      padding: 0 vr(.5);
      margin-bottom: 0;
    }
  }
  dt {
    @include typi(s2);
    @include bold;
    text-transform: uppercase;
  }
  dd {
    margin-left: 0;
    @include black;
    @include typi(num);
    color: #6c1d41;
  }
}