.info-box {
  background-color: #6c1d41;
  color: #fff;
  padding: vr(1);
  margin-bottom: vr(1);
  text-align: center;
  @media #{$medium-up} {
    padding: vr(1) vr(2);
  }
  > div {
    text-align: left;
    padding-top: vr(.5);
    ul {
      margin-left: 0;
    }
  }
  ul {
    strong,em {
      display: block;
    }
  }
}
.toggle {
  border: 0;
  &:before {
    content: '';
    display: inline-block;
    @extend .ico-desplega;
  }
  &.close {
    &:before {
      @extend .ico-plega;
    }
  }
}
