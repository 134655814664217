// cercador mòbil i desktop

.s {

	// estils comuns
	@include typi('s1');
	fieldset {
		border: 0;
		padding: 0;
		margin: 0;
		position: relative;
	}
	input {
		display: inline-block;
		vertical-align: middle;
		border-radius: 3px;
		border: 0;
		line-height: 2.45;
		padding: 0 1em;
		background: #fff;
		box-sizing: border-box;
		// -webkit-appearance: none;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #ccc;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: #ccc;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: #ccc;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: #ccc;
		}
	}
	button {
		margin: 0;
		padding: 0;
		border: 0;
		background: transparent;
		width: rem-calc(32);
		height: rem-calc(32);
		line-height: 0;
		position: absolute;
		top: 0;
		right: 0;
	}

	// estils mòbil
	&--mobile {
		margin-left: -$column-gutter/2;
		margin-right: -$column-gutter/2;
		padding: vr(1) $column-gutter/2;
		border-top: 1px solid #fff;
		@media #{$medium-up} {
			display: none;
		}
		input {
			width: 100%;
			padding-right: 3em;
		}
	}

	// estils desktop
	&--desktop {
		.js & {
			display: none;
		}
		@media #{$medium-up} {
			position: absolute;
			top: rem-calc(75);
			right: $column-gutter/2;
			padding: vr(.5);
			border-radius: 3px;
			z-index: 1;
			filter: drop-shadow(0 0 2px rgba(0, 0, 0, .25));
			// filter: drop-shadow(9px 9px 9px rgba(0,0,0,0.3));
			&:before {
				content: '';
				display: block;
				@include triangle(8px, #e9e9e9, bottom);
				position: absolute;
				right: 5px;
				top: -16px;
			}
			input {
				width: 10em;
				height: rem-calc(30);
				// padding-left: rem-calc(30);
				text-align: left;
				// &:focus {
				// 	width: auto;
				// }
			}
			// button {
			// 	height: rem-calc(29);
			// }
		}	
	}
}