.adjunts {
  padding: vr(1) 0;
  margin: vr(2) 0;
  position: relative;
  h2 {
    text-align: center;
  }
  @media #{$medium-up} {
    padding-left: vr(2);
    padding-right: vr(2);
    &:before,
    &:after {
      width: rem-calc(380);
      left: 50%;
      margin-left: rem-calc(-190);
    }
  } 
  @media #{$large-up} {
    padding-left: vr(6);
    padding-right: vr(6);
  }
  &:before,
  &:after {
    display: block;
    content: '';
    width: rem-calc(200);
    height: 1px;
    background-color: #d9d9d9;
    position: absolute;
    left: 50%;
    margin-left: rem-calc(-100);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  &__list {
    margin-left: 0;
    text-align: center;
    li {
      padding-left: 0;
      margin-bottom: vr(1);
      margin-top: rem-calc(50);
      &:before {
        display: none;
      }
      a {
        color: $body-font-color;
        border-bottom: 0;
        position: relative;
        //padding-left: 40px;
        display: block;
        &:hover {
          text-decoration: underline;
        }
        &:before {
          display: block;
          position: absolute;
          left: 46%;
          top: rem-calc(-30);
          content: '';
        }
        span {
          @include typi(s1);
          @include italic;
          display: block;
          color: $primary-color;
        }
      }
    }
  }
  &__jobs {
    margin-left: 0;
    text-align: center;
    li {
      padding-left: 0;
      margin-bottom: vr(1);
      &:before {
        display: none;
      }
      a {
        color: $body-font-color;
        border-bottom: 0;
        position: relative;
        //padding-left: 40px;
        display: block;
        &:hover {
          text-decoration: underline;
        }
        &:before {
          display: block;
          //position: absolute;
          //left: rem-calc(55);
          margin: 0 auto;
          margin-bottom: rem-calc(15);
          top: 0;
          content: '';
        }
        span {
          @include typi(s1);
          @include italic;
          display: block;
          color: $primary-color;
        }
      }
    }
  }
  &--png {
    a:before {
      @extend .ico-arxiu-png;
    }
  }
  &--txt {
    a:before {
      @extend .ico-arxiu-txt;
    }
  }
  &--pdf {
    a:before {
      @extend .ico-arxiu-pdf;
    }
  }
  &--key {
    a:before {
      @extend .ico-arxiu-keynote;
    }
  }
  &--ppt {
    a:before {
      @extend .ico-arxiu-ppt;
    }
  }
  &--doc {
    a:before {
      @extend .ico-document-doc;
    }
  }
  &--jpg {
    a:before {
      @extend .ico-arxiu-jpg;
    }
  }
  &.full {
    padding-left: 0;
    padding-right: 0;
  }
}
