.jobs {
	text-align: center;
	/**margin-top: vr(1);*/
	min-height: rem-calc(360);
	padding-top: 2.5rem;
	padding-left: 2.5rem;

	@media #{$large-up} {
		text-align: left;
	}
	h2 {
		margin-bottom: vr(.25);
		@include regular;
		@include typi(t3);
		letter-spacing: 0;
		@media #{$large-up} {
			text-align: left;
		}
	}
	p {
		@include typi(s1);
		margin-bottom: vr(.5);
	}
	h3 {
		@include black;
		margin-bottom: vr(.5);
	}
}